import React, { useState, useEffect, useRef } from 'react';

import { Wheel } from 'react-custom-roulette';
import BotonCompra from './BotonCompra';
import { Button, VStack, Box } from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import axios from 'axios';
import { ReactComponent as MercadoPagoIcon } from './mpagos.svg';

import PopupPacks from './PopupPacks';

import 'animate.css';

const CustomWheel = ({ datos, urlPagos, ob_premio = {}, payment_id = '' }) => {
  const [pais, setPais] = useState();
  const [opciones, setOpcoines] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const [ruleta, setRuleta] = useState();
  const [isPaid, setIsPaid] = useState(false);
  const [isIdle, setIsIdle] = useState(false);
  const [daleputo, setDaleputo] = useState(false);
  const [precio, setPrecio] = useState(null);
  const [mustSpin, setMustSpin] = useState(false);
  const [ruletalocal, setLocal] = useState();
  const [ruletaextranjera, setExtranjero] = useState();
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [disable, setDisable] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [openPacks, setOpenPacks] = useState(false);
  const [userActivityInterval, setUserActivityInterval] = useState(null);
  const [handleUserActivity, setHandleUserActivity] = useState(null);
  const botonRef = useRef(null);
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  let params = useParams();
  /*var dataRuleta = [
    {
      option: 'Video Chat ' + props.minChat,
      style: { textColor: 'black', backgroundColor: '#EDBD50' }, // 1%
    },
    { option: 'Sexting ' + props.minSext }, //60%
    { option: 'Pack fotos' }, // 70%
    { option: 'Pack videos' }, //60%
    { option: 'Dick Rate' }, //75%
  ];*/

  useEffect(() => {
    //  console.log(ob_premio, 'url pagos', urlPagos, payment_id);

    axios
      .get('https://get.geojs.io/v1/ip/geo.json')
      .then(response => {
        sessionStorage.pais = response.data.country_code;
        setPais(response.data.country_code);
      })
      .catch(error => {
        console.log(error);
      });

    setPais(sessionStorage.pais);

    function handleUserActivity() {
      if (!userActivityInterval) {
        const id = setInterval(() => {
          setIsIdle(true);
          setTimeout(() => {
            setIsIdle(false);
          }, 2000);
        }, 5000);

        setUserActivityInterval(id);
      }
    }

    // Escuchar eventos de actividad del usuario en todo el documento
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    setHandleUserActivity(handleUserActivity);
    return () => {
      // Limpiar eventos cuando se desmonta el componente
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearInterval(userActivityInterval);
    };

    //console.log('el pais: ', sessionStorage.pais);
  }, []);

  const backgroundColors = ['#EED9F2', '#343036', '#B65796'];
  const textColors = ['#B65796', '#EDBD50', '#EED9F2'];
  const outerBorderColor = '#eeeeee';
  const outerBorderWidth = 15;
  const innerBorderColor = '#30261a';
  const innerBorderWidth = 5;
  const innerRadius = 20;
  const radiusLineColor = '#30261a';
  const radiusLineWidth = 8;
  const fontSize = 13;
  const textDistance = 60;
  const spinDuration = 1.0;

  useEffect(() => {
    if (payment_id) setIsPaid(true);
    if (ob_premio) {
      setPrizeNumber(ob_premio.index);
    }
    //console.log(prize);
    for (let i = 0; i < datos.length; i++) {
      datos[i].option =
        datos[i].option.length > 20
          ? datos[i].option.substring(0, 20) + '...'
          : datos[i].option;
    }
    setRuleta(datos);

    //console.log(sessionStorage.premio);
  }, []);

  useEffect(() => {
    if (precio == null) {
      axios
        .get(
          'https://server-mb9m.onrender.com/precio/' + params.hash.slice(0, 6)
        )
        .then(res => {
          console.log(
            res.data.pais,
            sessionStorage.pais,
            res.data.pais === sessionStorage.pais
          );
          if (sessionStorage.pais === res.data.pais) {
            setPrecio(res.data.precio?.split('|')[0]);
            sessionStorage.setItem('precio', res.data.precio?.split('|')[0]);
          }
          if (sessionStorage.pais !== res.data.pais) {
            setPrecio(res.data.precio?.split('|')[1]);
            sessionStorage.setItem('precio', res.data.precio?.split('|')[1]);
          }
        });
    }
  }, [precio]);

  const handleSpinClick = () => {
    if (urlPagos === 'preview') {
      NiceModal.show('infomodalpreview');
      setIsPaid(true);
    } else {
      sessionStorage.setItem('ruletahash', params.hash.slice(0, 6));
      localStorage.clear('giro');
      localStorage.clear('terminado');
      localStorage.clear('premios');
      setOpenPacks(true);
    }
  };

  const handlePaidSpinClick = () => {
    setDaleputo(true);
    setPrizeNumber(ob_premio.index);
    setMustSpin(true);
    setDisable(true);
  };

  const toggleOpenPack = val => {
    console.log('el toggle', val);
    setOpenPacks(val);
  };

  return (
    <VStack spacing={3}>
      {openPacks && <PopupPacks urlPagos={urlPagos} onClose={toggleOpenPack} />}
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={datos}
        backgroundColors={backgroundColors}
        textColors={textColors}
        fontSize={fontSize}
        outerBorderColor={outerBorderColor}
        outerBorderWidth={outerBorderWidth}
        innerRadius={innerRadius}
        innerBorderColor={innerBorderColor}
        innerBorderWidth={innerBorderWidth}
        radiusLineColor={radiusLineColor}
        radiusLineWidth={radiusLineWidth}
        spinDuration={spinDuration}
        // perpendicularText
        textDistance={textDistance}
        onStopSpinning={() => {
          setTimeout(() => {
            payment_id =
              payment_id !== ''
                ? payment_id
                : btoa(sessionStorage.public_key.slice(0, 9));
            NiceModal.show('ruletapremio', {
              ob_premio,
              payment_id,
              terminar: sessionStorage.urlPago ? true : false,
            });
          }, 1000);
          setMustSpin(false);
        }}
      />
      {!daleputo ? (
        <BotonCompra
          price={precio}
          isPaid={isPaid}
          disable={disable}
          handlePaidSpinClick={handlePaidSpinClick}
          handleSpinClick={handleSpinClick}
        />
      ) : (
        <Button disabled={true}>⏳</Button>
      )}
      {isPaid ? (
        <> </>
      ) : (
        <VStack>
          <small style={{ fontWeight: 700 }}>
            (Pesos {sessionStorage.pais === 'UY' ? 'Uruguayos ' : 'Argentinos'})
          </small>
        </VStack>
      )}
    </VStack>
  );
};

export default CustomWheel;
