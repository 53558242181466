import React, { ReactNode, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { Helmet } from 'react-helmet';
import {
  ChakraProvider,
  extendTheme,
  Box,
  Checkbox,
  Stack,
  Text,
  Heading,
  Select,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Spinner,
  VStack,
  Grid,
  Button,
  Container,
  Input,
  Flex,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Center,
  WrapItem,
  Wrap,
} from '@chakra-ui/react';
import { Wheel } from 'react-custom-roulette';
import BotonCompra from './BotonCompra';
import NiceModal from '@ebay/nice-modal-react';
import TagPremio from './TagPremio';
import PreviewContent from './PreviewContent';
import Footer from './Footer';
import axios from 'axios';
import NavBarRuleta from './NavBarRuleta';

const RuletaPack = ({ pais }) => {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  let params = useParams();
  const [paymentid, setPaymentid] = useState(query.get('payment_id'));

  const [{ data: getData, loading: getLoading, error: getError }, refetch] =
    useAxios({
      //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
      url:
        'https://server-mb9m.onrender.com/getPremios/' +
        params.hash.slice(0, 6),
      useCache: false,
    });

  const [
    { data: getDataRuleta, loading: getLoadingRuleta, error: getErrorRuleta },
    refetchRuleta,
  ] = useAxios({
    //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
    url:
      'https://server-mb9m.onrender.com/getPutiruleta/' +
      params.hash.slice(0, 6),
    useCache: false,
  });

  const [
    { data: getDataPago, loading: getLoadingPago, error: getErrorPago },
    refetchPago,
  ] = useAxios(
    {
      //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
      url:
        'https://server-mb9m.onrender.com/getMultiPremio/' +
        query.get('payment_id') +
        '/' +
        pais +
        '/' +
        params.hash.slice(0, 6),
      useCache: false,
    },
    { manual: true }
  );
  const theme = extendTheme({
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          fontFamily: "'Poppins', sans-serif;",
        },
      }),
    },
  });
  const [prizeNumber, setPrizeNumber] = useState();
  const [desactivarBoton, setDesactivarBoton] = useState(false);
  const [mustSpin, setMustSpin] = useState(false);
  const [dataRuleta, setDataRuleta] = useState([]);
  const [thumbs, setThumbs] = useState();
  const [toggleRueda, setToggleRueda] = useState(false);
  var arry_test = [];

  useEffect(() => {
    refetchPago().then(res => {
      var giros = JSON.parse(res.data.giros);
      if (giros === 1) localStorage.setItem('terminado', true);
    });
    const tmbs = axios
      .get(
        'https://putiruleta.com/files/scandir.php?hash=' +
          params.hash.slice(0, 6)
      )
      .then(res => {
        try {
          var thmbs = res.data?.map((item, i) => {
            if (i > 1) {
              return params.hash.slice(0, 6) + '/' + item;
            }
          });
          setThumbs(thmbs.filter(x => x !== undefined));
          console.log(thmbs.filter(x => x !== undefined));
        } catch (error) {
          //console.log(error);
        }
      });
  }, []);

  useEffect(() => {
    try {
      if (getData !== undefined) {
        arry_test = [];
        var temp = getData.resultado.map(item => {
          arry_test.push({
            option:
              item.titulo.length > 20
                ? item.titulo.substring(0, 20) + '...'
                : item.titulo,
          });
          return item;
        });

        setDataRuleta(arry_test);
      } else {
        refetch();
      }
    } catch (getError) {
      console.log(getError);
    }
  }, [getData, getDataRuleta]);

  const [ob_premio, setOb_premio] = useState({
    titulo: '',
    index: 0,
    metadata: '',
    descarga: 0,
  });

  const [check, setCheck] = useState(false);
  const [girosRestantes, setGirosRestantes] = useState(null);
  const chekeoTiro = () => {
    setCheck(!check);
  };
  useEffect(() => {
    console.log(paymentid);
    if (paymentid != null) {
      refetchPago()
        .then(res => {
          var premios = JSON.parse(res.data.premios);
          var giros = JSON.parse(res.data.giros);
          setGirosRestantes(giros);
          for (let prop in premios) {
            if (parseInt(prop) === parseInt(giros) - 1) {
              setPrizeNumber(premios[prop].index);
              console.log(
                'titulo index hash?',
                premios[prop].titulo,
                premios[prop].index,
                premios[prop].hash
              );
              if (localStorage.ganados != null) {
                var temp = localStorage.getItem('ganados');
                if (!temp.includes(premios[prop].hash)) {
                  localStorage.setItem(
                    'ganados',
                    temp + '|' + premios[prop].hash
                  );
                }
              } else {
                localStorage.setItem('ganados', premios[prop].hash);
              }

              setOb_premio({
                titulo: premios[prop].titulo,
                index: premios[prop].index,
                metadata: premios[prop].hash,
                descarga: premios[prop].descarga,
              });
            }
          }
          if (giros === 0) {
            var dominio = window.location.hostname;
            var protocolo = window.location.protocol;
            var puerto = window.location.port;
            //console.log(`${protocolo}//${dominio}/${params.hash.slice(0, 6)}`);
            if (puerto != null)
              var volar = `${protocolo}//${dominio}:${puerto}/${params.hash.slice(
                0,
                6
              )}`;
            else
              var volar = `${protocolo}//${dominio}/${params.hash.slice(0, 6)}`;
            window.location.href = volar;
          }

          localStorage.setItem('premios', JSON.stringify(premios));
          if (localStorage.giro === undefined) {
            localStorage.setItem('giro', giros);
          } else {
            if (parseInt(localStorage.giro) === parseInt(giros)) {
              return;
            }
            if (parseInt(localStorage.giro) > parseInt(giros)) {
              localStorage.setItem('giro', giros);
              window.location.reload();
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [check]);

  if (getLoading || getLoadingPago || getLoadingRuleta)
    return (
      <ChakraProvider theme={theme}>
        <Container width="80vw" minWidth="60vw">
          <Stack spacing={2} minHeight="20%">
            <Spinner
              thickness="24px"
              speed="0.65s"
              emptyColor="#a5e0cf"
              color="#f5a7d5"
              style={{
                position: 'relative',
                zIndex: 7,
                width: '151px',
                height: '151px',
                margin: '37% auto',
                transform: 'translate( -50%, -50% )',
              }}
            />
          </Stack>
        </Container>
      </ChakraProvider>
    );
  if (getError || getErrorRuleta) return <p>Error! {getError.message}</p>;

  const backgroundColors = ['#EED9F2', '#343036', '#B65796'];
  const textColors = ['#B65796', '#EDBD50', '#EED9F2'];
  const outerBorderColor = '#eeeeee';
  const outerBorderWidth = 15;
  const innerBorderColor = '#30261a';
  const innerBorderWidth = 5;
  const innerRadius = 20;
  const radiusLineColor = '#30261a';
  const radiusLineWidth = 8;
  const fontSize = 13;
  const textDistance = 60;
  const spinDuration = 1.0;

  const handlePaidSpinClick = () => {
    console.log(
      localStorage.getItem('giro'),
      localStorage.getItem('giro') == 1
    );
    setDesactivarBoton(true);
    if (localStorage.getItem('giro') == 1) {
      console.log('pacate');
      localStorage.setItem('terminado', true);
    }
    setMustSpin(true);
  };
  if (girosRestantes != null)
    return (
      <>
        <ChakraProvider theme={theme}>
          <NavBarRuleta ruleta={params.hash.slice(0, 6)} />
          <Flex
            style={{
              backgroundImage: "url('../../fondo_kawaii_putiruleta4.jpg')",
              minWidth: '100%',
            }}
          >
            <Container m={0} width="100vw" minWidth="100%">
              <Center>
                <VStack>
                  <Box>
                    <Wheel
                      mustStartSpinning={mustSpin}
                      prizeNumber={prizeNumber}
                      data={dataRuleta}
                      backgroundColors={backgroundColors}
                      textColors={textColors}
                      fontSize={fontSize}
                      outerBorderColor={outerBorderColor}
                      outerBorderWidth={outerBorderWidth}
                      innerRadius={innerRadius}
                      innerBorderColor={innerBorderColor}
                      innerBorderWidth={innerBorderWidth}
                      radiusLineColor={radiusLineColor}
                      radiusLineWidth={radiusLineWidth}
                      spinDuration={spinDuration}
                      // perpendicularText
                      textDistance={textDistance}
                      onStopSpinning={() => {
                        NiceModal.show('ruletamultipremio', {
                          ob_premio,
                          paymentid,
                          chekeoTiro,
                        });
                        setMustSpin(false);
                      }}
                    />
                  </Box>
                  <Box>
                    <Button
                      disabled={desactivarBoton}
                      onClick={handlePaidSpinClick}
                      size={'lg'}
                      backgroundColor={'#EED9F2'}
                      color={'black'}
                      px={6}
                      colorScheme={'pink'}
                      bg={'pink.400'}
                      _hover={{ bg: 'pink.500', color: 'white' }}
                    >
                      Girar ({localStorage.getItem('giro')}) ⚡️
                    </Button>
                  </Box>
                </VStack>
              </Center>
              <Center m="31px auto">
                <Checkbox colorScheme="green" defaultChecked>
                  Soy mayor de edad en mi pais de residencia.
                </Checkbox>
              </Center>

              <Divider borderColor="blackAlpha.500" />
              {thumbs?.length > 0 && (
                <Box>
                  <Center m={'13px 0'}>
                    <Heading size="md">Premios:</Heading>
                  </Center>
                  <Box>
                    <PreviewContent
                      minHeigth="250px"
                      thumbs={thumbs}
                      ruleta={params.hash.slice(0, 6)}
                    />
                  </Box>
                </Box>
              )}
              <Footer />
            </Container>
          </Flex>
        </ChakraProvider>
      </>
    );
};

export default RuletaPack;
