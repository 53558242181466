import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  AlertIcon,
  Alert,
  Box,
  Heading,
  Center,
  SimpleGrid,
} from '@chakra-ui/react';
import { TiWarning } from 'react-icons/ti';
import { RiHeartPulseFill } from 'react-icons/ri';
import { FaCopy } from 'react-icons/fa';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
export default NiceModal.create(
  ({ link_inv = '', link_creadora = '', alerta = '' }) => {
    const modal = useModal();
    const [copied, setCopied] = useState(false);
    const handleCopiar = () => {
      navigator.clipboard.writeText(link_inv);
    };
    const handleDesvincular = () => {
      sessionStorage.clear();
      window.location.reload(false);
    };
    const handleComenzarDeNuevo = () => {
      sessionStorage.removeItem('ruletahash');
      sessionStorage.removeItem('premioshash');
      sessionStorage.setItem('nueva', true);
      sessionStorage.precio = 'ok';
      window.location.reload(false);
    };
    return (
      <>
        <Modal
          id="infomodal"
          closeOnOverlayClick={false}
          isOpen={modal.visible}
          onClose={() => modal.hide()}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody p={3}>
              {alerta === 1 && (
                <Box>
                  <Box textAlign="center" py={3} px={6}>
                    <Center>
                      <TiWarning fontSize={'50px'} color={'green.500'} />
                    </Center>
                    <Heading as="h2" size="xl" mt={6} mb={2}>
                      Atencion!
                    </Heading>
                    <Text justifyContent="center" textAlign="center">
                      Quieres desvincular mercadopago?
                    </Text>
                  </Box>
                </Box>
              )}
              {!link_inv && alerta === 0 ? (
                <Box>
                  <Box textAlign="center" py={3} px={6}>
                    <Center>
                      <TiWarning fontSize={'50px'} color={'green.500'} />
                    </Center>
                    <Heading as="h2" size="xl" mt={6} mb={2}>
                      Atencion!
                    </Heading>
                    <Text justifyContent="center" textAlign="center">
                      Quieres comenzar nuevamente?
                    </Text>
                  </Box>
                </Box>
              ) : (
                alerta === '' && (
                  <Box>
                    <Box textAlign="center" py={3} px={6}>
                      <Center>
                        <RiHeartPulseFill
                          fontSize={'50px'}
                          color={'green.500'}
                        />
                      </Center>
                      <Text as="h2" size="xl" mt={6} mb={2}>
                        Enlace a tu PutiRuleta!💕
                      </Text>
                      <Text
                        marginTop="15px"
                        justifyContent="center"
                        textAlign="center"
                        onClick={() => handleCopiar()}
                      >
                        {link_inv}
                      </Text>
                    </Box>
                  </Box>
                )
              )}
            </ModalBody>
            <ModalFooter>
              {alerta === 1 && (
                <SimpleGrid
                  columns={{ base: 1, md: 2 }}
                  spacingX={1}
                  spacingY={1}
                  width="100%"
                >
                  <Button onClick={() => handleDesvincular()}>Si</Button>
                  <Button onClick={() => modal.hide()}>No</Button>
                </SimpleGrid>
              )}
              {!link_inv && alerta === 0 ? (
                <SimpleGrid
                  columns={{ base: 1, md: 2 }}
                  spacingX={1}
                  spacingY={1}
                  width="100%"
                >
                  <Button onClick={() => handleComenzarDeNuevo()}>Si</Button>
                  <Button onClick={() => modal.hide()}>No</Button>
                </SimpleGrid>
              ) : (
                alerta === '' && (
                  <SimpleGrid
                    columns={{ base: 1, md: 1 }}
                    spacingX={1}
                    spacingY={1}
                    width="100%"
                  >
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(link_inv);
                        modal.hide();
                      }}
                    >
                      Copiar!
                    </Button>
                    <Button onClick={() => modal.hide()}>Cerrar</Button>
                  </SimpleGrid>
                )
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  }
);
