import React, { useEffect, useState } from 'react';
import { Button, Box, Spinner } from '@chakra-ui/react';
import { ReactComponent as MercadoPagoIcon } from './mpagos.svg';

export default function BotonCompra({
  isPaid,
  disable,
  price,
  botonRef,
  handlePaidSpinClick,
  handleSpinClick,
  gorrito,
}) {
  const [handleUserActivity, setHandleUserActivity] = useState(null);
  const [userActivityInterval, setUserActivityInterval] = useState(null);
  const [isIdle, setIsIdle] = useState(false);

  const handleClick = () => {
    if (isPaid) {
      handlePaidSpinClick();
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      setHandleUserActivity(null);
      botonRef.current.classList.remove('animate__heartBeat');
      botonRef.current.classList.remove('animate__animated');
    } else {
      handleSpinClick();
    }
  };

  useEffect(() => {
    function handleUserActivity() {
      if (!userActivityInterval) {
        const id = setInterval(() => {
          setIsIdle(true);
          setTimeout(() => {
            setIsIdle(false);
          }, 2000);
        }, 5000);

        setUserActivityInterval(id);
      }
    }

    // Escuchar eventos de actividad del usuario en todo el documento
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);
    setHandleUserActivity(handleUserActivity);
    return () => {
      // Limpiar eventos cuando se desmonta el componente
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearInterval(userActivityInterval);
    };
  }, []);

  function formatPrice(price) {
    const decimalPart = (price % 1).toFixed(2).substring(2);

    if (isNaN(decimalPart)) {
      setTimeout(() => {
        return formatPrice(price); // Recargar la función con el mismo valor
      }, 2000);
    }

    return (
      <>
        {parseInt(price).toFixed(0)}.
        <span style={{ verticalAlign: 'text-top', fontSize: '13px' }}>
          {decimalPart}
        </span>
      </>
    );
  }

  return (
    <Button
      position="relative"
      className={
        'animate__animated ' +
        (isPaid ? (isIdle ? ' animate__heartBeat' : '') : '')
      }
      disabled={disable}
      backgroundColor={'#EED9F2'}
      ref={botonRef}
      onClick={isPaid ? handlePaidSpinClick : handleSpinClick}
      size="lg"
      color={'black'}
      px={6}
      colorScheme={'pink'}
      bg={'pink.400'}
      _hover={{ bg: 'pink.500', color: 'white' }}
    >
      {gorrito && (
        <img
          src="../../gorrito.png"
          alt="Gorrito de cumpleaños"
          style={{
            position: 'absolute',
            top: '-37px',
            left: '165px',
            width: '60px',
            height: '60px',
          }}
        />
      )}
      {isPaid ? (
        <Box>Jugar ⚡️</Box>
      ) : price != null ? (
        <>
          <Box style={{ paddingLeft: '15px' }}>
            <MercadoPagoIcon style={{ width: '35px', height: '35px' }} />
          </Box>
          <Box
            style={{
              paddingLeft: '15px',
              paddingRight: '2px',
              fontSize: '13px',
            }}
          >
            {sessionStorage.pais === 'UY' ? 'UYU ' : 'ARS'}
          </Box>
          {price ? (
            <Box>{formatPrice(price)}</Box>
          ) : (
            <Box>
              <Spinner size="xs" />
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Spinner size="xs" />
        </Box>
      )}
    </Button>
  );
}
