import { useState } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Center,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

function Popup() {
  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(true);

  const handleClose = () => {
    if (isChecked) {
      sessionStorage.setItem('noabrir', true);
    }
    setIsOpen(false);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Icon as={CheckIcon} color="green.500" mr={2} />
            ¡Muy bien!
          </Center>
        </ModalHeader>
        <ModalBody>
          Agrega al menos 5 premios para configurar el precio.
        </ModalBody>
        <ModalFooter>
          <Checkbox
            isChecked={isChecked}
            onChange={handleCheckboxChange}
            mr={2}
          >
            No volver a mostrar
          </Checkbox>
          <Button colorScheme="blue" onClick={handleClose}>
            Aceptar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default Popup;
