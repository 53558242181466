import { ColorModeScript } from '@chakra-ui/react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import NiceModal from '@ebay/nice-modal-react';
import InfoModal from './components/InfoModal';
import NuevoMercadoPagoModal from './components/NuevoMercadoPagoModal';
import ModalRuletaPremio from './components/ModalRuletaPremio';
import ModalDescarga from './components/multipack/ModalDescarga';
import ModalNoDescarga from './components/multipack/ModalNoDescarga';
import InfoPreviewModal from './components/InfoPreviewModal';
import AnimatedRouters from './components/AnimatedRouters';
import SocialProfilesModal from './components/NavBar/SocialProfilesModal';
import { BrowserRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  extendTheme,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
NiceModal.register('infomodal', InfoModal);
NiceModal.register('redes', SocialProfilesModal);
NiceModal.register('nuevomercadopagomodal', NuevoMercadoPagoModal);
NiceModal.register('infomodalpreview', InfoPreviewModal);
NiceModal.register('ruletapremio', ModalRuletaPremio);
NiceModal.register('ruletamultipremionodescarga', ModalNoDescarga);
NiceModal.register('ruletamultipremio', ModalDescarga);

root.render(
  <ChakraProvider>
    <NiceModal.Provider>
      <BrowserRouter>
        <AnimatedRouters />
      </BrowserRouter>
    </NiceModal.Provider>
  </ChakraProvider>
);
