import { useState, useEffect, useRef } from 'react';

import { Wheel } from 'react-custom-roulette';
import useAxios from 'axios-hooks';

import {
  VStack,
  ChakraProvider,
  extendTheme,
  Box,
  Spinner,
} from '@chakra-ui/react';
import NiceModal from '@ebay/nice-modal-react';
import 'animate.css';

const PreviewWheel = ({ recargar = false }) => {
  const theme = extendTheme({
    colors: {
      rosa: {
        50: '#FFE6F1',
        100: '#FEB8D8',
        200: '#FE8BBE',
        300: '#FD5DA5',
        400: '#FD308C',
        500: '#FD0272',
        600: '#CA025B',
        700: '#980145',
        800: '#65012E',
        900: '#330017',
      },
    },
    components: {
      Button: {
        marginTop: '5px',
        baseStyle: {
          color: 'black.500',
        },
      },
      Input: {
        variants: {
          filled: {
            field: {
              bg: 'red.100',
            },
          },
        },
      },
    },

    styles: {
      global: (props: StyleFunctionProps) => ({
        colors: {
          text: 'white',
          rosa: {
            50: '#FFE6F1',
            100: '#FEB8D8',
            200: '#FE8BBE',
            300: '#FD5DA5',
            400: '#FD308C',
            500: '#FD0272',
            600: '#CA025B',
            700: '#980145',
            800: '#65012E',
            900: '#330017',
          },
        },
        body: {
          fontFamily: "'Poppins', sans-serif;",
        },
      }),
    },
  });

  const [{ data: getData, loading: getLoading, error: getError }, refetch] =
    useAxios({
      //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
      url:
        'https://server-mb9m.onrender.com/getPremios/' +
        (sessionStorage.ruletahash !== undefined
          ? sessionStorage.ruletahash
          : 'exit'),
      useCache: false,
    });

  var dataRuletaInitValue = [
    { option: '?' },
    { option: '?' },
    { option: '?' },
    { option: '?' },
    { option: '?' },
  ];

  useEffect(() => {
    if (recargar === true) {
      // console.log('que onda puto');
      refetch().then(res =>
        res.data.resultado.map(x => {
          const index = dataRuletaInitValue.findIndex(el => el.option === '?');
          if (index !== -1) {
            dataRuletaInitValue[index] = { option: '🍭' };
          } else {
            dataRuletaInitValue[index] = { option: '?' };
          }
          setRuleta(dataRuletaInitValue);
        })
      );
    }
  }, [recargar]);

  useEffect(() => {
    if (getData !== undefined) {
      var cantidadPremios = 0;
      if (
        sessionStorage.premioshash !== undefined &&
        sessionStorage.premioshash !== ''
      )
        cantidadPremios = sessionStorage.premioshash.split(',').length;
      if (cantidadPremios <= 5) {
        dataRuletaInitValue = [
          { option: '?' },
          { option: '?' },
          { option: '?' },
          { option: '?' },
          { option: '?' },
        ];
        var arrTitulos = getData.resultado?.map(x => {
          const index = dataRuletaInitValue.findIndex(el => el.option === '?');
          if (index !== -1) {
            dataRuletaInitValue[index] = { option: '🍭' };
          } else {
            dataRuletaInitValue[index] = { option: '?' };
          }
          setRuleta(dataRuletaInitValue);
        });
      } else {
        var dataRuletaInitValue = [];
        var arrTitulos = getData.resultado.map(x => {
          dataRuletaInitValue.push({ option: '🍭' });
          setRuleta(dataRuletaInitValue);
        });
      }
    } else {
      setRuleta(dataRuletaInitValue);
    }
  }, []);

  const [ruleta, setRuleta] = useState(dataRuletaInitValue);

  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [disable, setDisable] = useState(false);

  useEffect(() => {}, []);

  const backgroundColors = ['#EED9F2', '#343036', '#B65796'];
  const textColors = ['#B65796', '#EDBD50', '#EED9F2'];
  const outerBorderColor = '#eeeeee';
  const outerBorderWidth = 15;
  const innerBorderColor = '#30261a';
  const innerBorderWidth = 5;
  const innerRadius = 20;
  const radiusLineColor = '#30261a';
  const radiusLineWidth = 8;
  const fontSize = 13;
  const textDistance = 60;
  const spinDuration = 1.0;
  const size = 200;

  if (getLoading)
    return (
      <ChakraProvider theme={theme}>
        <Box
          height="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner
            thickness="12px"
            speed="0.65s"
            emptyColor="#a5e0cf"
            color="#f5a7d5"
            style={{
              position: 'relative',
              zIndex: 7,
              width: '75px',
              height: '75px',
              margin: '37% auto',
              transform: 'translate( -50%, -50% )',
            }}
          />
        </Box>
      </ChakraProvider>
    );
  if (getError) return <p>Error!</p>;

  return (
    <VStack spacing={3} className="probando">
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={ruleta}
        backgroundColors={backgroundColors}
        textColors={textColors}
        fontSize={45}
        baseSize={size}
        outerBorderColor={outerBorderColor}
        outerBorderWidth={outerBorderWidth}
        innerRadius={innerRadius}
        innerBorderColor={innerBorderColor}
        innerBorderWidth={innerBorderWidth}
        radiusLineColor={radiusLineColor}
        radiusLineWidth={radiusLineWidth}
        spinDuration={spinDuration}
        // perpendicularText
        textDistance={textDistance}
      />
    </VStack>
  );
};

export default PreviewWheel;
