import React, { useEffect, useContext, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { userContext } from '../AppContext';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Link,
  Center,
} from '@chakra-ui/react';
import { BsCheckCircle } from 'react-icons/bs';

export default function Auth() {
  let params = useParams();
  const navigate = useNavigate();
  const [pais, setPais] = useState();
  useEffect(() => {
    if (params.creadora !== 'no') {
      const arr_data = atob(params.creadora).split('|');
      console.log(arr_data);
      if (arr_data.length === 1) {
        sessionStorage.setItem('public_key', arr_data[0]);
      } else {
        sessionStorage.setItem('access_token', arr_data[0]);
        sessionStorage.setItem('public_key', arr_data[1]);
        sessionStorage.setItem('email', arr_data[2]);
        sessionStorage.setItem('nickname', arr_data[3]);
        console.log(params.creadora);
      }
    }
    navigate('/');
  }, []);

  return <>Wait...</>;
}
