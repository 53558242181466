import { ReactNode, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import {
  ChakraProvider,
  extendTheme,
  Box,
  Stack,
  Text,
  Select,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Spinner,
  VStack,
  Grid,
  Button,
  Container,
  Input,
  Flex,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Center,
  WrapItem,
  Wrap,
  Image,
  Heading,
  Checkbox,
  CheckboxGroup,
} from '@chakra-ui/react';

import TagPremio from './TagPremio';
import CustomWheel from './CustomWheel';
import useWeightRandom from '../hooks/useWeightRandom';
import { IoMdRefresh, IoMdArrowRoundBack } from 'react-icons/io';
import { CgLink } from 'react-icons/cg';
import PreviewContent from './PreviewContent';
import Footer from './Footer';
import NiceModal from '@ebay/nice-modal-react';
import axios from 'axios';

export default function Preview() {
  let params = useParams();
  const [pais, setPais] = useState();

  const [
    { data: postData, loading: postLoading, error: postError },
    executePost,
  ] = useAxios(
    {
      //url: 'https://server-mb9m.onrender.com/postPutiruleta',
      url: 'https://server-mb9m.onrender.com/getRandom',
      method: 'POST',
    },
    { manual: true }
  );

  const [{ data: getData, loading: getLoading, error: getError }, refetch] =
    useAxios({
      //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
      url: 'https://server-mb9m.onrender.com/getPremios/' + params.hash,
      useCache: false,
    });

  const [
    { data: getDataRuleta, loading: getLoadingRuleta, error: getErrorRuleta },
    refetchRuleta,
  ] = useAxios({
    //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
    url: 'https://server-mb9m.onrender.com/getPutiruleta/' + params.hash,
    useCache: false,
  });

  const [dataRuleta, setDataRuleta] = useState([]);
  const [premio, setPremio] = useState();
  const [terminar, setTerminar] = useState(false);
  const [isCreadoraViewing, setIsCreadoraViewing] = useState(false);
  const [thumbs, setThumbs] = useState();

  const theme = extendTheme({
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          fontFamily: "'Poppins', sans-serif;",
        },
      }),
    },
  });

  useEffect(() => {
    if (getDataRuleta !== undefined) {
      if (getDataRuleta.resultado[0].url_pago !== '') setTerminar(true);
    }
  }, [getLoadingRuleta]);

  useEffect(() => {
    axios
      .get('https://get.geojs.io/v1/ip/geo.json')
      .then(response => {
        sessionStorage.pais = response.data.country_code;
        setPais(response.data.country_code);
      })
      .catch(error => {
        console.log(error);
      });

    const tmbs = axios
      .get(
        'https://putiruleta.com/files/scandir.php?hash=' +
          sessionStorage.ruletahash
      )
      .then(res => {
        //  console.log('si tiene sentido', res);
        var thmbs =
          res.data.length > 0
            ? res.data.map((item, i) => {
                if (i > 1) {
                  return sessionStorage.ruletahash + '/' + item;
                }
              })
            : null;
        if (thmbs !== null) setThumbs(thmbs.filter(x => x !== undefined));
      });
  }, []);

  const [prizeNumber, setPrizeNumber] = useState();

  useEffect(() => {
    sessionStorage.setItem('ruletahash', params.hash);
    sessionStorage.setItem('preview', 1);

    try {
      if (getData !== undefined) {
        //console.log(getData);
        var losItems = [];
        var losWeight = [];
        var arry_test = [];
        var hashes = [];
        var descarga = [];
        var temp = getData.resultado.map(item => {
          if (item.objeto_media !== '' && item.objeto_media !== 'undefined') {
            descarga.push(1);
            arry_test.push({
              option: item.titulo,
              weight: item.probabilidad,
              metadata: item.objeto_media,
            });
          } else {
            descarga.push(0);
            arry_test.push({
              option: item.titulo,
              weight: item.probabilidad,
            });
          }
          hashes.push(item.hash);
          losItems.push(item.titulo);
          losWeight.push(item.probabilidad);
          return item;
        });
        // console.log(temp, losItems, losWeight, hashes, descarga);

        executePost({
          data: {
            paymentid: atob(params.idcheck),
            ruletahash: params.hash,
            losItems,
            losWeight,
            hashes,
            descarga,
          },
        }).then(res => {
          //   console.log('resultaod de executepost ', res);
          //  console.log('set prize number ', res.data);
          setPrizeNumber(res.data.premio.index);
          //    console.log(arry_test[res.data.premio.index].metadata !== undefined);
          if (arry_test[res.data.premio.index].metadata !== '') {
            setPremio({
              index: res.data.premio.index,
              titulo: res.data.premio.titulo,
              metadata: arry_test[res.data.premio.index].metadata,
              descarga: descarga[res.data.premio.index],
            });
          } else {
            setPremio({
              index: res.data.premio.index,
              titulo: res.data.premio.titulo,
              descarga: descarga[res.data.premio.index],
            });
          }
        });

        setDataRuleta(arry_test);
      } else {
        //refetch();
      }
    } catch (getError) {
      console.log(getError);
    }
  }, [getData]);

  const navigate = useNavigate();
  const handleBack = () => {
    window.history.back();
  };
  const handleMostrarLink = () => {
    NiceModal.show('infomodal', {
      link_inv: 'https://putiruleta.com/' + sessionStorage.ruletahash,
    });
  };

  if ((getLoading || postLoading || getLoadingRuleta) && !premio)
    return (
      <ChakraProvider theme={theme}>
        <Container width="80vw" minWidth="60vw">
          <Stack spacing={2} minHeight="20%">
            <Spinner
              thickness="24px"
              speed="0.65s"
              emptyColor="#a5e0cf"
              color="#f5a7d5"
              style={{
                position: 'relative',
                zIndex: 7,
                width: '151px',
                height: '151px',
                margin: '37% auto',
                transform: 'translate( -50%, -50% )',
              }}
            />
          </Stack>
        </Container>
      </ChakraProvider>
    );
  if (getError || getErrorRuleta) return <p>Error! {getError.message}</p>;

  return (
    <>
      <ChakraProvider theme={theme}>
        <Flex
          style={{
            backgroundImage: "url('../../fondo_kawaii_putiruleta4.jpg')",
            minWidth: '100%',
          }}
        >
          <Container m={0} width="100vw" minWidth="100%">
            <Box>
              <CustomWheel
                datos={dataRuleta}
                ob_premio={
                  premio && {
                    titulo: premio.titulo,
                    index: premio.index,
                    metadata: premio.metadata && premio.metadata,
                    descarga: premio.descarga,
                  }
                }
                prize={prizeNumber}
                urlPagos="preview"
              />
            </Box>
            <Center m="31px auto">
              <Checkbox colorScheme="green" defaultChecked>
                Soy mayor de edad en mi pais de residencia.
              </Checkbox>
            </Center>

            <Divider borderColor="blackAlpha.500" />
            {thumbs && (
              <Box>
                <Center m={'13px 0'}>
                  <Heading size="md">Premios:</Heading>
                </Center>
                <Box>
                  <PreviewContent minHeigth="250px" thumbs={thumbs} />
                </Box>
              </Box>
            )}
            <Center m={'13px 0'}>
              <Button
                width="100%"
                maxWidth="391px"
                onClick={() => {
                  window.location.reload(false)();
                }}
              >
                <IoMdRefresh />
                Recargar
              </Button>
            </Center>
            <Center m={'13px 0'}>
              <Button
                width="100%"
                maxWidth="391px"
                onClick={() => {
                  handleBack();
                }}
              >
                <IoMdArrowRoundBack />
                Volver
              </Button>
            </Center>
            {terminar && (
              <Center m={'13px 0'}>
                <Button
                  width="100%"
                  maxWidth="391px"
                  onClick={() => {
                    handleMostrarLink();
                  }}
                >
                  <CgLink />
                  Obtener link
                </Button>
              </Center>
            )}
            <Center m={'13px 0'}>
              <small>
                Recarga la pagina si quieres testear las probabilidades.
              </small>
            </Center>
            <Footer />
          </Container>
        </Flex>
      </ChakraProvider>
    </>
  );
}
