import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  AlertIcon,
  Alert,
  Box,
  Heading,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  ButtonGroup,
  VStack,
  HStack,
} from '@chakra-ui/react';
import { FaCopy } from 'react-icons/fa';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import axios from 'axios';
import { useEffect } from 'react';
import { CgLink } from 'react-icons/cg';
import { IoMdRefresh, IoMdArrowRoundBack } from 'react-icons/io';
import PremiosPanel from './PremiosPanel';
import useAxios from '../../hooks/useAxios';

export default NiceModal.create(props => {
  const dataRuleta = [
    'Minutos de Video Chat',
    'Sexting',
    'Un Pack Fotos',
    'Un Pack videos',
    'DickRate',
  ];
  const modal = useModal();
  const handleEntrarClick = () => {};
  const { width, height } = useWindowSize();
  const [isRaining, setIsRaining] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [oneTime, setOneTime] = useState(true);
  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/gotBuyerEmail/' + props.paymentid,
  });

  const handleRedirect = () => {
    setClicked(true);

    window.location.href =
      'https://putiruleta.com/descarga/?payment_id=' +
      props.paymentid +
      '&ruleta=' +
      sessionStorage.ruletahash;
  };

  useEffect(() => {
    console.log('LAS PROPS', props);
    if (oneTime) {
      axios.post('https://server-mb9m.onrender.com/getDown/', {
        paymentid: props.paymentid,
      });
      setOneTime(false);
    }
  }, []);

  const [input, setInput] = useState('');

  const handleInputChange = e => setInput(e.target.value);

  const guardarMail = (mail = '') => {
    axios.post('https://server-mb9m.onrender.com/postMailBuyer', {
      //axios.post('https://server-mb9m.onrender.com/guardar-mail', {
      email: mail !== '' ? mail : input,
      payment_id: props.paymentid,
    });
  };
  const isError = input === '';

  const [needEmail, setNeedEmail] = useState(true);
  const [descargar, setDescargar] = useState(false);
  useEffect(() => {
    if (props.paymentid && !loading) {
      if (response !== null) {
        if (response !== 'nomail') {
          sessionStorage.mail = response;
          setNeedEmail(false);
        }
        if (response === 'nomail') {
          if (sessionStorage.mail !== undefined) {
            guardarMail(sessionStorage.mail);
            setNeedEmail(false);
          }
        }
      }
      var terminado = localStorage.getItem('terminado');
      var descargas = 0;
      if (JSON.parse(terminado) === true) {
        for (let prop in premios) {
          if (premios[prop].descarga !== 0) {
            setDescargar(true);
            descargas = 1;
          }
        }
        var paymentid = props.paymentid;
        var ruleta = sessionStorage.ruletahash;
        var mail = sessionStorage.mail;

        axios.post('https://server-mb9m.onrender.com/mailcliente/', {
          paymentid,
          ruleta,
          mail,
          descargas,
        });
      }
    }
  }, [response]);

  const premios = JSON.parse(localStorage.premios);

  const { chekeoTiro } = props;
  if (!loading)
    return (
      <>
        {isRaining && (
          <Confetti
            colors={['#E098C8', '#76603B', '#B65494', '#66646A']}
            width={width}
            height={height}
            numberOfPieces={200}
          />
        )}
        <Modal
          id="ruletamultipremio"
          size="sm"
          closeOnOverlayClick={false}
          isOpen={modal.visible}
          onClose={() => {
            setIsRaining(false);
            chekeoTiro();
            modal.hide();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody p={3}>
              <Box>
                <Center>
                  <Box width={'100%'}>
                    <VStack>
                      <Text
                        fontWeight={'700'}
                        fontSize={{ base: '2x1', md: '3x1', lg: '4x1' }}
                      >
                        Ganaste
                      </Text>
                      <Text
                        fontWeight={'700'}
                        fontSize={{ base: '3em', md: '3em', lg: '5x1' }}
                        style={{
                          textAlign: 'center',
                          maxWidth: '79%',
                          margin: '0 auto',
                        }}
                      >
                        ¡{props.ob_premio.titulo}!
                      </Text>
                      <PremiosPanel premios={premios} />
                      {needEmail ? (
                        <FormControl isInvalid={isError}>
                          <FormLabel>Email</FormLabel>
                          <ButtonGroup isAttached variant="outline">
                            <Input
                              type="email"
                              value={input}
                              onChange={handleInputChange}
                            />
                            <Button
                              colorScheme={'pink'}
                              bg="pink.500"
                              color="white"
                              fontSize={{
                                base: '0.8em',
                                sm: '0.8em',
                                md: '0.8em',
                                lg: '0.8em',
                              }}
                              _hover={{ bg: '#e14cbf' }}
                              onClick={() => {
                                guardarMail();
                                setNeedEmail(false);
                              }}
                            >
                              Continuar
                            </Button>
                          </ButtonGroup>
                          {!isError ? (
                            <FormHelperText>
                              Ingresa tu email para continuar.
                            </FormHelperText>
                          ) : (
                            <FormErrorMessage>Obligatorio.</FormErrorMessage>
                          )}
                        </FormControl>
                      ) : (
                        descargar && (
                          <Center style={{ paddingTop: '50px' }}>
                            <VStack>
                              <Button
                                rounded={'full'}
                                px={6}
                                colorScheme={'pink'}
                                size="40px"
                                bg="pink.500"
                                color="white"
                                _hover={{ bg: '#e14cbf' }}
                                style={{
                                  width: '300px',
                                  height: '70px',
                                }}
                                isLoading={clicked}
                                loadingText="Descargando"
                                onClick={() => {
                                  handleRedirect();
                                }}
                              >
                                Descargar Premios
                              </Button>
                            </VStack>
                          </Center>
                        )
                      )}
                      <small>
                        Te enviamos un email con el detalle de tus premios y
                        como reclamarlos.
                      </small>
                    </VStack>
                  </Box>
                </Center>
              </Box>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
});
