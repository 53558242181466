import { useState, useEffect } from 'react';
import {
  Button,
  Center,
  Checkbox,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useMediaQuery,
  SimpleGrid,
  Heading,
  Text,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { css } from '@emotion/react';
import axios from 'axios';
const styles = {
  active: css`
    background-color: red;
    /* Otros estilos que deseas aplicar al botón presionado */
  `,
};
function TusRuletasPopup({ ruletas, onDale }) {
  const [isOpen, setIsOpen] = useState(true);
  const [ruletaElejida, setRuletaElejida] = useState();
  const [selectedRuleta, setSelectedRuleta] = useState(false);
  const [temp, setTemp] = useState(
    !selectedRuleta ? sessionStorage.ruletahash : selectedRuleta
  );
  useEffect(() => {
    console.log('ruletas ', ruletas);
    axios
      .get('https://server-mb9m.onrender.com/getPremios/' + ruletas[0])
      .then(res => {
        const arrayPR = res.data.resultado.map(x => x.hash);
      });
  }, []);

  const handleCancelar = () => {
    if (typeof onDale === 'function') {
      onDale(false); // Llamada a la función onClose con el nuevo valor
    }
    setIsOpen(false);
  };
  const handleClose = () => {
    sessionStorage.ruletahash = ruletaElejida;
    if (sessionStorage.ruletahash !== temp) {
      axios
        .get(
          'https://server-mb9m.onrender.com/getPremios/' +
            sessionStorage.ruletahash
        )
        .then(res => {
          sessionStorage.setItem('ruletahash', sessionStorage.ruletahash);
          const arrayPR = res.data.resultado.map(x => x.hash);
          console.log('los premio', res.data.resultado, arrayPR);
          sessionStorage.setItem('premioshash', arrayPR.join(','));
        });
      if (ruletas.length > 0) window.location.reload(true);
    }

    if (typeof onDale === 'function') {
      onDale(false); // Llamada a la función onClose con el nuevo valor
    }
    setIsOpen(false);
  };

  const handleRuleta = val => {
    setSelectedRuleta(val);
    console.log(val);
    setRuletaElejida(val);
  };

  const isSmall = useMediaQuery('(max-width: 400px)');
  const isMedium = useMediaQuery('(max-width: 800px)');
  const ruletaHash = sessionStorage.getItem('ruletahash');
  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>🍭</ModalHeader>
        <ModalBody>
          <Heading style={{ paddingBottom: '15px' }}>
            <Center>
              <Icon as={CheckIcon} color="green.500" mr={2} />
              {!ruletaElejida ? sessionStorage.ruletahash : ruletaElejida}
            </Center>
          </Heading>
          <Text> Tus ruletas:</Text>
          <SimpleGrid
            columns={isSmall[0] ? 2 : 3}
            spacing={1}
            w={isSmall[0] ? '320px' : isMedium[0] ? '380px' : '420px'}
          >
            {ruletas.map(item => (
              <Button
                className={
                  item === ruletaHash
                    ? 'boton-rosa'
                    : item === selectedRuleta
                    ? 'boton-verde'
                    : ''
                }
                key={item}
                onClick={() => handleRuleta(item)}
              >
                {item}
              </Button>
            ))}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <SimpleGrid columns={2} spacing={1} w={'100%'}>
            <Button onClick={handleCancelar}>Cancelar</Button>
            <Button colorScheme="blue" onClick={handleClose}>
              Aceptar
            </Button>
          </SimpleGrid>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TusRuletasPopup;
