import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  AlertIcon,
  Alert,
  Box,
  Heading,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  ButtonGroup,
  VStack,
} from '@chakra-ui/react';
import { FaCopy } from 'react-icons/fa';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import axios from 'axios';
import { useEffect } from 'react';
import { CgLink } from 'react-icons/cg';
import { IoMdRefresh, IoMdArrowRoundBack } from 'react-icons/io';
import useAxios from '../../hooks/useAxios';

export default NiceModal.create(props => {
  const modal = useModal();
  const [copied, setCopied] = useState(false);
  const handleEntrarClick = () => {};
  const { width, height } = useWindowSize();
  const [isRaining, setIsRaining] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [terminar, setTerminar] = useState(false);
  const { response, loading, error } = useAxios({
    method: 'get',
    url: '/gotBuyerEmail/' + props.paymentid,
  });

  const { chekeoTiro } = props;
  const [input, setInput] = useState('');

  const handleInputChange = e => setInput(e.target.value);

  const guardarMail = (mail = '') => {
    axios.post('https://server-mb9m.onrender.com/postMailBuyer', {
      //axios.post('https://server-mb9m.onrender.com/guardar-mail', {
      email: mail !== '' ? mail : input,
      payment_id: props.paymentid,
    });
  };
  const isError = input === '';

  const [needEmail, setNeedEmail] = useState(true);
  const [oneTime, setOneTime] = useState(true);

  useEffect(() => {
    if (props.paymentid && !loading)
      if (response !== null) {
        if (response !== 'nomail') {
          sessionStorage.mail = response;
          setNeedEmail(false);
        }
        if (response === 'nomail') {
          if (sessionStorage.mail !== undefined) {
            guardarMail(sessionStorage.mail);
            setNeedEmail(false);
          }
        }
      }
  }, [response]);

  useEffect(() => {
    console.log('LAS PROPS', props);
    if (oneTime) {
      axios.post('https://server-mb9m.onrender.com/getDown/', {
        paymentid: props.paymentid,
      });
      setOneTime(false);
    }
  }, []);
  if (!loading)
    return (
      <>
        {isRaining && (
          <Confetti
            colors={['#E098C8', '#76603B', '#B65494', '#66646A']}
            width={width}
            height={height}
            numberOfPieces={200}
          />
        )}
        <Modal
          id="ruletamultipremionodescarga"
          size="sm"
          closeOnOverlayClick={false}
          isOpen={modal.visible}
          onClose={() => {
            setIsRaining(false);
            chekeoTiro();
            modal.hide();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody p={3}>
              <Box>
                <Center>
                  {!copied ? (
                    <Box>
                      <VStack>
                        <Text
                          fontWeight={'700'}
                          fontSize={{ base: '2x1', md: '3x1', lg: '4x1' }}
                        >
                          Ganaste
                        </Text>
                        <Text
                          fontWeight={'700'}
                          fontSize={{ base: '3em', md: '3em', lg: '5x1' }}
                          style={{
                            textAlign: 'center',
                            maxWidth: '79%',
                            margin: '0 auto',
                          }}
                        >
                          ¡{props.ob_premio.titulo}!
                        </Text>
                        {needEmail ? (
                          <FormControl isInvalid={isError}>
                            <FormLabel>Email</FormLabel>
                            <ButtonGroup isAttached variant="outline">
                              <Input
                                type="email"
                                value={input}
                                onChange={handleInputChange}
                              />
                              <Button
                                colorScheme={'pink'}
                                bg="pink.500"
                                color="white"
                                fontSize={{
                                  base: '0.8em',
                                  sm: '0.8em',
                                  md: '0.8em',
                                  lg: '0.8em',
                                }}
                                _hover={{ bg: '#e14cbf' }}
                                onClick={() => {
                                  guardarMail();
                                  setNeedEmail(false);
                                }}
                              >
                                Continuar
                              </Button>
                            </ButtonGroup>
                            {!isError ? (
                              <FormHelperText>
                                Ingresa tu email para contactarte con la
                                creadora.
                              </FormHelperText>
                            ) : (
                              <FormErrorMessage>Obligatorio.</FormErrorMessage>
                            )}
                          </FormControl>
                        ) : (
                          <>
                            <Text>
                              Reclama tu premio con el siguiente codigo{' '}
                            </Text>
                            <Alert
                              style={{ marginTop: '45px' }}
                              status="info"
                              alignItems="center"
                              justifyContent="center"
                              textAlign="center"
                              width={'400px'}
                            >
                              <FaCopy
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    props.paymentid
                                  );
                                  setCopied(true);
                                  setTimeout(() => {
                                    setCopied(false);
                                  }, 3000);
                                }}
                              />
                              <Box
                                paddingLeft={5}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    props.paymentid
                                  );
                                  setCopied(true);
                                  setTimeout(() => {
                                    setCopied(false);
                                  }, 3000);
                                }}
                              >
                                {props.paymentid}
                              </Box>
                            </Alert>
                            <Text mt={'15px'} p={'15px'}>
                              <small>
                                Codigo unico para reclamar tu premio con el/la
                                creador/a de contenido.
                              </small>
                            </Text>
                          </>
                        )}
                      </VStack>
                    </Box>
                  ) : (
                    <>
                      <Box>
                        <VStack>
                          <Text
                            fontWeight={'700'}
                            fontSize={{ base: '2x1', md: '3x1', lg: '4x1' }}
                          >
                            Ganaste
                          </Text>
                          <Text
                            fontWeight={'700'}
                            fontSize={{ base: '3em', md: '3em', lg: '5x1' }}
                            style={{
                              textAlign: 'center',
                              maxWidth: '79%',
                              margin: '0 auto',
                            }}
                          >
                            ¡{props.ob_premio.titulo}!
                          </Text>
                          <Text>
                            Reclama tu premio con el siguiente codigo{' '}
                          </Text>
                        </VStack>
                        <Alert
                          marginTop={'45px'}
                          status="success"
                          justifyContent="center"
                          width={'400px'}
                        >
                          <AlertIcon />
                          <Box>Copiado!</Box>
                        </Alert>
                        <Text mt={'15px'} p={'15px'}>
                          <small>
                            Codigo unico para reclamar tu premio con el/la
                            creador/a de contenido.
                          </small>
                        </Text>
                      </Box>
                    </>
                  )}
                </Center>
              </Box>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
});
