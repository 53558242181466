import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Integracion from './Integracion';
import Auth from './Auth';
import Preview from './Preview';
import Home from './Home';
import { JustAPanel } from './JustAPanel';
import { Box, Text, Link, VStack, Code, Grid } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import useAxios from 'axios-hooks';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import InfoModal from './InfoModal';
import DescargaIndividual from './DescargaIndividual';
import RuletaPack from './RuletaPack';

import axios from 'axios';

NiceModal.register('infomodal', InfoModal);

export default function AnimatedRouters() {
  const [pais, setPais] = useState();
  const [image, setImage] = useState();

  const createHash = () => {
    const hash = Array.from(Array(6), () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join('');

    return hash;
  };

  const [{ data: getData, loading: getLoading, error: getError }] = useAxios(
    //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
    'https://server-mb9m.onrender.com/getPutiruleta/' +
      (sessionStorage.ruletahash !== undefined
        ? sessionStorage.ruletahash
        : 'exit')
  );

  const [
    { data: postData, loading: postLoading, error: postError },
    executePost,
  ] = useAxios(
    {
      //url: 'https://server-mb9m.onrender.com/postPutiruleta',
      url: 'https://server-mb9m.onrender.com/postPutiruleta',
      method: 'POST',
    },
    { manual: true }
  );

  useEffect(() => {
    document.body.style.backgroundImage = "url('fondo_kawaii_putiruleta4.jpg')";
    if (!sessionStorage.pais) {
      axios
        .get('https://get.geojs.io/v1/ip/geo.json')
        .then(response => {
          sessionStorage.pais = response.data.country_code;
          setPais(response.data.country_code);

          const path = window.location.pathname;
          const hash = path.substring(1);
          var elGet = '';
          if (hash.includes('ruleta')) {
            elGet = hash.split('/')[1];
          } else {
            elGet = hash.slice(0, 6);
          }
          axios
            .get('https://server-mb9m.onrender.com/precio/' + elGet)
            .then(res => {
              console.log(
                res.data.pais,
                sessionStorage.pais,
                res.data.pais === sessionStorage.pais,
                'precio:',
                res.data.precio
              );
              if (sessionStorage.pais === res.data.pais) {
                sessionStorage.setItem(
                  'precio',
                  res.data.precio?.split('|')[0]
                );
              }
              if (sessionStorage.pais !== res.data.pais) {
                sessionStorage.setItem(
                  'precio',
                  res.data.precio?.split('|')[1]
                );
              }
            });
        })
        .catch(error => {
          console.log(error);
        });
    }
    let data = {};
    if (!sessionStorage.ruletahash) {
      const e = createHash();
      if (sessionStorage.public_key)
        data = {
          ruletahash: e,
          public_key: sessionStorage.public_key,
          access_token: sessionStorage.access_token,
        };
      else {
        data = {
          ruletahash: e,
        };
      }
      executePost({ data }).then(res => {
        if (res.data.result.affectedRows === 1) sessionStorage.ruletahash = e;
      });
    } else {
      if (sessionStorage.public_key) {
        axios
          .get(
            'https://server-mb9m.onrender.com/isowner/' +
              sessionStorage.ruletahash +
              '/' +
              sessionStorage.public_key
          )
          .then(res => {
            console.log(res);
            if (res.data === 1) {
              sessionStorage.removeItem('precio');
              sessionStorage.removeItem('ruletahash');
              sessionStorage.removeItem('premioshash');
              window.location.reload(false)();
            }
          });
      } else {
        if (sessionStorage.getItem('primeravez') != null) {
          //esperar 10 segundos y abrir popup
          var cont = sessionStorage.getItem('primeravez');
          sessionStorage.setItem('primeravez', parseInt(cont) + 1);
        } else {
          sessionStorage.setItem('primeravez', 1);
        }
      }
    }
  }, []);

  const location = useLocation();

  if (!pais) {
    axios
      .get('https://get.geojs.io/v1/ip/geo.json')
      .then(response => {
        sessionStorage.pais = response.data.country_code;
        console.log(response.data.country_code);
        const path = window.location.pathname;
        const hash = path.substring(1);
        console.log(hash);
        /* let arrayRuletas = ['a0uxwm', 'hhrzvf', 'vswflc', 'xc7z1q'];
        if (
          response.data.country_code !== 'UY' &&
          response.data.country_code !== 'AR' &&
          arrayRuletas.some(valor => hash.includes(valor))
        ) {
          // Obtener la URL actual
          const urlActual = window.location.href;

          // Separar la URL en partes para obtener el dominio y la ruta
          const urlPartes = urlActual.split('/');
          const dominio = urlPartes[2]; // El dominio estará en la posición 2 del array

          // Agregar "paypal" al comienzo del dominio
          const nuevoDominio = 'paypal.' + dominio;

          // Reemplazar el dominio original con el nuevo dominio en la URL
          const nuevaURL = urlActual.replace(dominio, nuevoDominio);

          // Redireccionar a la nueva URL
          window.location.href = nuevaURL;
        }
*/
        setPais(response.data.country_code);
      })
      .catch(error => {
        console.log(error);
      });
    return <>Loading</>;
  }

  return (
    <AnimatePresence>
      <NiceModal.Provider>
        <Routes location={location} key={location.path}>
          <Route path="/:hash/:creadora" element={<Auth />} />

          <Route path="/preview/:hash/:idcheck" element={<Preview />} />
          <Route
            path="/descarga-individual/:hash/"
            element={<DescargaIndividual />}
          />
          <Route
            path="/ruleta-pack/:hash/"
            element={<RuletaPack pais={pais} />}
          />
          <Route path="/:hash" element={<Home pais={pais} image={image} />} />
          <Route path="/" element={<JustAPanel />} />
          <Route path="/metodos-de-pago" element={<Integracion />} />
        </Routes>
      </NiceModal.Provider>
    </AnimatePresence>
  );
}
