import { useState } from 'react';
import { Input, Button, FormControl, FormLabel } from '@chakra-ui/react';
import axios from 'axios';

function Formulario({ paymentId }) {
  const [email, setEmail] = useState('');
  const [isSent, setIsSent] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      const response = await axios.post(
        'https://server-mb9m.onrender.com/enviar-link',
        {
          paymentId: paymentId, // Reemplaza con la variable paymentId adecuada
          email: email,
        }
      );
      setIsSent(true);
      console.log(response.data); // Maneja la respuesta del servidor según tus necesidades
    } catch (error) {
      console.error(error);
    }
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </FormControl>

        <Button type="submit" colorScheme="blue" mt={4}>
          Enviar
        </Button>
      </form>
      {isSent && <small>Email actualizado con exito</small>}
    </>
  );
}

export default Formulario;
