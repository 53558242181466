import React, { useEffect, useState, useRef } from 'react';
import {
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import { Icon } from '@chakra-ui/react';
import { FaEye } from 'react-icons/fa';
import PremioViewr from './PremioViewr';

function Ojito(val) {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleTogglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
      {isPopupOpen && <PremioViewr hash={val} onClose={handleClosePopup} />}
      <div style={{ margin: '5px' }} onClick={handleTogglePopup}>
        <Icon as={FaEye} boxSize={3} color={'white'} />
      </div>
    </>
  );
}

export default function TagPremio({ titulo = '', hash, onEvent }) {
  const [cerrar, setCerrar] = useState(false);
  const tag = useRef(null);
  const [
    { data: postData, loading: postLoading, error: postError },
    executePost,
  ] = useAxios(
    {
      url: 'https://server-mb9m.onrender.com/delPremio',
      method: 'POST',
    },
    { manual: true }
  );
  let data = {};
  const handleClose = () => {
    const confirmDelete = window.confirm('¿Eliminar Premio?');
    if (confirmDelete) {
      data.hash = hash;
      data.key = sessionStorage.public_key;
      data.ruleta_hash = sessionStorage.ruletahash;
      // console.log(data);
      //console.log('asdfasdf');
      executePost({ data }).then(() => {
        onEvent();
        setCerrar(true);
      });
    }
  };

  if (!cerrar) {
    return (
      <Tag
        size={['sm', 'md', 'lg']}
        display={'inline-block'}
        borderRadius="2"
        variant="solid"
        thickness="4px"
        speed="0.65s"
        opacity={1}
        hash={hash}
        key={hash}
        ref={tag}
        width="100%"
        style={{ backgroundColor: 'black' }}
        minWidth="113px"
      >
        <HStack m={'9px auto'} justifyContent={'space-between'}>
          {postLoading ? (
            <Spinner size="xs" />
          ) : (
            <>
              {(titulo.includes('Foto') ||
                (titulo.includes('Video') &&
                  !titulo.toLowerCase().includes('chat'))) &&
                sessionStorage.public_key && <Ojito val={hash} key={hash} />}
              <TagLabel>
                <small>{titulo}</small>
              </TagLabel>
              {sessionStorage.public_key && (
                <TagCloseButton
                  size={['sm', 'md', 'lg']}
                  onClick={() => {
                    handleClose();
                  }}
                />
              )}
            </>
          )}
        </HStack>
      </Tag>
    );
  }
}
