import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
} from '@chakra-ui/react';

import axios from 'axios';

function App() {
  const [pais, setPais] = useState();

  useEffect(() => {
    axios
      .get('https://get.geojs.io/v1/ip/geo.json')
      .then(response => {
        sessionStorage.pais = response.data.country_code;
        setPais(response.data.country_code);

        const path = window.location.pathname;
        const hash = path.substring(1);
        axios
          .get('https://server-mb9m.onrender.com/precio/' + hash.slice(0, 6))
          .then(res => {
            console.log(
              res.data.pais,
              sessionStorage.pais,
              res.data.pais === sessionStorage.pais,
              'precio:',
              res.data.precio
            );
            if (sessionStorage.pais === res.data.pais) {
              sessionStorage.setItem('precio', res.data.precio?.split('|')[0]);
            }
            if (sessionStorage.pais !== res.data.pais) {
              sessionStorage.setItem('precio', res.data.precio?.split('|')[1]);
            }
          });
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  if (!pais) {
    axios
      .get('https://get.geojs.io/v1/ip/geo.json')
      .then(response => {
        sessionStorage.pais = response.data.country_code;
        setPais(response.data.country_code);
      })
      .catch(error => {
        console.log(error);
      });
    return <>Loading</>;
  }
}

export default App;
