import { ReactNode, useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  HStack,
  MenuItem,
  MenuDivider,
  SimpleGrid,
  VStack,
  Tag,
  Text,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
} from '@chakra-ui/react';
import axios from 'axios';
import {
  FaTelegram,
  FaInstagram,
  FaTiktok,
  FaCoffee,
  FaTwitter,
} from 'react-icons/fa';
import { Icon } from 'react-icons-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as OnlyFans } from './onlyfans.svg';
import { ReactComponent as Fansly } from './fansly2.svg';
import { ReactComponent as MercadoPagoIcon } from './mpagos.svg';

const NavLink = ({ children }: { children: ReactNode }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}
  >
    {children}
  </Link>
);

export default function NavBarRuleta({ ruleta }) {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isInstagram, setInstagram] = useState();
  const [isOnlyfans, setIsOnlyfans] = useState();
  const [isFansly, setIsFansly] = useState();
  const [isCafecito, setIsCafecito] = useState();
  const [isTwitter, setIsTwitter] = useState();
  const [isTikTok, setIsTikTok] = useState();
  const [vistas, setVistas] = useState();
  const [isConnected, setisConnected] = useState(false);
  const [count, setCount] = useState();
  const [isTelegram, setIsTelegram] = useState();
  useEffect(() => {
    axios
      .get(
        `https://server-mb9m.onrender.com/checkpkey/${sessionStorage.public_key}|${ruleta}`
      )
      .then(res => {
        if (res.data.status === 'online') {
          setisConnected(true);
        } else {
          setisConnected(false);
        }
      });
    axios.get(`https://server-mb9m.onrender.com/redes/${ruleta}`).then(res => {
      console.log(res.data.retornar.contacto[0]);
      setInstagram(res.data.retornar.contacto[0].ig);
      setIsTelegram(res.data.retornar.contacto[0].telegram_name);
      setVistas(res.data.retornar.contacto[0].vistas);
      setIsFansly(res.data.retornar.contacto[0].fansly);
      setIsTwitter(res.data.retornar.contacto[0].twitter);
      setIsTikTok(res.data.retornar.contacto[0].tiktok);
      setIsCafecito(res.data.retornar.contacto[0].cafecito);
      setIsOnlyfans(res.data.retornar.contacto[0].only);
      let emptyCount = 0;

      for (let prop in res.data.retornar.contacto[0]) {
        if (
          res.data.retornar.contacto[0].hasOwnProperty(prop) &&
          res.data.retornar.contacto[0][prop] !== ''
        ) {
          emptyCount++;
        }
      }
      console.log(emptyCount);
      setCount(emptyCount);
      console.log(count);
    });
  }, [ruleta]);

  const handleRelogin = () => {
    if (sessionStorage.pais === undefined) {
      axios.get('https://get.geojs.io/v1/ip/geo.json').then(response => {
        const pais = response.data.country_code;

        if (pais === 'UY') {
          sessionStorage.pais = 'UY';
          console.log(
            `https://auth.mercadopago.com.uy/authorization?client_id=1817367863397264&response_type=code&platform_id=mp&state=recon-${ruleta}|UY&redirect_uri=https://putibot.app/auth`
          );

          window.location.href = `https://auth.mercadopago.com.uy/authorization?client_id=1817367863397264&response_type=code&platform_id=mp&state=recon-${ruleta}|UY&redirect_uri=https://putibot.app/auth`;
        }
        if (pais === 'AR') {
          sessionStorage.pais = 'AR';
          console.log(
            `https://auth.mercadopago.com.ar/authorization?client_id=3237931361464200&response_type=code&platform_id=mp&state=recon-${ruleta}|AR&redirect_uri=https://putibot.app/auth`
          );
          window.location.href = `https://auth.mercadopago.com.ar/authorization?client_id=3237931361464200&response_type=code&platform_id=mp&state=recon-${ruleta}|AR&redirect_uri=https://putibot.app/auth`;
        }
      });
    } else {
      const pais = sessionStorage.pais;
      if (pais === 'UY') {
        window.location.href = `https://auth.mercadopago.com.uy/authorization?client_id=1817367863397264&response_type=code&platform_id=mp&state=recon-${ruleta}|UY&redirect_uri=https://putibot.app/auth`;
        sessionStorage.pais = 'UY';
      }
      if (pais === 'AR') {
        window.location.href = `https://auth.mercadopago.com.ar/authorization?client_id=3237931361464200&response_type=code&platform_id=mp&state=recon-${ruleta}|AR&redirect_uri=https://putibot.app/auth`;
        sessionStorage.pais = 'AR';
      }
    }
  };

  return (
    <>
      <Box bg={'#EED9F2'} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7} style={{ zIndex: '99999' }}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}
                >
                  <Avatar
                    size={'sm'}
                    src={`https://robohash.org/${ruleta}?set=set4`}
                  />
                </MenuButton>

                <MenuList alignItems={'center'} bg={'#EED9F2'}>
                  <br />
                  <Center>
                    <Avatar
                      height={'121px'}
                      width={'121px'}
                      src={`https://robohash.org/${ruleta}?set=set4`}
                    />
                  </Center>
                  <br />
                  <MenuDivider />
                  <Center>
                    <VStack width={'100%'} style={{ padding: '0 10px' }}>
                      <SimpleGrid columns={count - 1} spacing={2}>
                        {isInstagram !== undefined && isInstagram !== '' ? (
                          <a
                            href={`https://www.instagram.com/${isInstagram}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaInstagram size={29} />
                          </a>
                        ) : (
                          ''
                        )}
                        {isOnlyfans !== undefined && isOnlyfans !== '' ? (
                          <a
                            href={`https://www.onlyfans.com/${isOnlyfans}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <OnlyFans size={29} />
                          </a>
                        ) : (
                          ''
                        )}
                        {isFansly !== undefined && isFansly !== '' ? (
                          <a
                            href={`https://www.fansly.com/${isFansly}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Fansly size={29} />
                          </a>
                        ) : (
                          ''
                        )}
                        {isTelegram !== undefined && isTelegram !== '' ? (
                          <a
                            href={`https://t.me/${isTelegram}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaTelegram size={29} />
                          </a>
                        ) : (
                          ''
                        )}
                        {isTikTok !== undefined && isTikTok !== '' ? (
                          <a
                            href={`https://tiktok.com/${isTikTok}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaTiktok size={29} />
                          </a>
                        ) : (
                          ''
                        )}
                        {isTwitter !== undefined && isTwitter !== '' ? (
                          <a
                            href={`https://twitter.com/${isTwitter}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaTwitter size={29} />
                          </a>
                        ) : (
                          ''
                        )}
                        {isCafecito !== undefined && isCafecito !== '' ? (
                          <a
                            href={`https://cafecito.app/${isCafecito}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaCoffee size={29} />
                          </a>
                        ) : (
                          ''
                        )}
                      </SimpleGrid>
                      <MenuDivider />

                      <SimpleGrid width={'100%'}>
                        <MenuItem
                          as={Button}
                          variant={'link'}
                          cursor={'pointer'}
                          minW={'100%'}
                          width={'100%'}
                          justifyContent={'space-between'} // Ajuste para espaciar el contenido horizontalmente
                          alignItems={'center'}
                          display={'flex'}
                          backgroundColor={isConnected ? 'pink.500' : '#009ee3'}
                          onClick={
                            isConnected
                              ? () => {
                                  window.location.href =
                                    'https://www.putiruleta.com/';
                                }
                              : () => {
                                  handleRelogin();
                                }
                          }
                        >
                          <Tag
                            size={'sm'}
                            variant={'solid'}
                            backgroundColor={
                              isConnected ? '#00f1ff' : '#FFD700'
                            }
                            fontWeight={'bold'}
                            color={'black'} // Cambio de color de las letras del hash
                          >
                            {ruleta}
                          </Tag>
                          <Flex alignItems={'center'} style={{ width: '60%' }}>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              width={'100%'}
                              height={'30px'}
                              borderRadius={'full'}
                              backgroundColor={'transparent'}
                              justifyContent={'center'}
                              alignItems={'center'}
                            >
                              <Text
                                color={'white'}
                                fontSize={'18px'}
                                width={'100%'}
                              >
                                {isConnected ? (
                                  'Editar ✍🏻'
                                ) : (
                                  <MercadoPagoIcon
                                    style={{ width: '35px', height: '35px' }}
                                  />
                                )}
                              </Text>
                            </Box>
                          </Flex>
                        </MenuItem>
                      </SimpleGrid>
                    </VStack>
                  </Center>
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
