import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  AlertIcon,
  Alert,
  Box,
  Heading,
  Center,
  SimpleGrid,
} from '@chakra-ui/react';
import { TiWarning } from 'react-icons/ti';
import { RiHeartPulseFill } from 'react-icons/ri';
import { FaCopy } from 'react-icons/fa';
import { MdOutlineSystemSecurityUpdateGood } from 'react-icons/md';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
export default NiceModal.create(() => {
  const modal = useModal();
  return (
    <>
      <Modal
        id="infomodalpreview"
        closeOnOverlayClick={false}
        isOpen={modal.visible}
        onClose={() => modal.hide()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={3}>
            <Box>
              <Box textAlign="center" py={3} px={6}>
                <Center>
                  <MdOutlineSystemSecurityUpdateGood
                    fontSize={'50px'}
                    color={'green.500'}
                  />
                </Center>
                <Heading as="h4" size="xl" mt={6} mb={2}>
                  Compra en Mercadopago
                </Heading>
                <Text justifyContent="center" textAlign="center">
                  Luego de la compra en mercadopago Tu cliente sera redirigido a
                  la ruleta con la opcion de "Tirar". Cierra esta ventana y
                  procede a tirar la ruleta.
                </Text>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacingX={1}
              spacingY={1}
              width="100%"
            >
              <Button onClick={() => modal.hide()}>Continuar simulacro</Button>
            </SimpleGrid>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
