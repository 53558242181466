import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  AlertIcon,
  Alert,
  Box,
  Heading,
  Center,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { FaCopy } from 'react-icons/fa';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useState } from 'react';
export default NiceModal.create(({ link_inv = '', link_creadora = '' }) => {
  const modal = useModal();
  const [copied, setCopied] = useState(false);
  const handleEntrarClick = () => {};
  const handleNuevaCreadora = () => {
    window.sessionStorage.clear();
    modal.hide();
  };
  return (
    <>
      <Modal
        id="s"
        closeOnOverlayClick={false}
        isOpen={modal.visible}
        onClose={() => modal.hide()}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody p={3}>
            <Box>
              <Box textAlign="center" py={3} px={6}>
                <CheckCircleIcon boxSize={'50px'} color={'green.500'} />
                <Heading as="h2" size="xl" mt={6} mb={2}>
                  Desvincularemos tu MercadoPago
                </Heading>
              </Box>
              <Box pb={3} style={{ cursor: 'pointer' }}></Box>
              <Text justifyContent="center" textAlign="center">
                Si quieres configurar una ruleta pero cobrar con otra cuenta,
                presiona Continuar. Podras hacer el proceso de vinculacion de
                MercadoPago nuevamente en la proxima Ruleta.
              </Text>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                handleNuevaCreadora();
              }}
            >
              Continuar
            </Button>
            <Button onClick={() => modal.hide()}>Cancelar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
