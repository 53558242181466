import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';

export const userContext = createContext({});

const initValue = {};

export const AppContextProvider = ({ children }) => {
  const [config, setConfig] = useState({
    url_api_prod: 'https://server-mb9m.onrender.com',
    url_api_dev: 'https://server-mb9m.onrender.com',
  });

  const [uploading, setUploading] = useState();
  const [isLogged, setIsLoged] = useState(false);
  const [navActive, setNavActive] = useState(initValue);
  const [user, setUser] = useState({
    avatar: '',
    hash: '',
    email: '',
    nickname: '',
    frase: '',
    precios: {
      minutos_10: '',
      minutos_30: '',
      minutos_60: '',
    },
  });

  function handleConfigUpdate(obj, key, val) {
    setConfig(Object.assign(obj, { [key]: val }));
  }

  function handleUserUpdate(obj, key, val) {
    setUser(Object.assign(obj, { [key]: val }));
  }

  const handleActiveNavLink = v => {
    switch (v) {
      case 1:
        setNavActive({
          paso1: true,
          paso2: false,
          paso3: false,
          paso4: false,
        });

        break;
      case 2:
        setNavActive({
          paso1: false,
          paso2: true,
          paso3: false,
          paso4: false,
        });

        break;
      case 3:
        setNavActive({
          paso1: false,
          paso2: false,
          paso3: true,
          paso4: false,
        });

        break;
      case 4:
        setNavActive();
        //console.log(navActive);
        break;
      default:
        break;
    }
  };

  const saveNickname = () => {
    axios
      .post(config.url_api_dev + '/nickname/save', {
        nickname: user.nickname,
        email: user.email,
      })
      .then(function (response) {
        ////console.log(response);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  const saveFrase = () => {
    axios
      .post(config.url_api_dev + '/frase/save', {
        frase: user.frase,
        email: user.email,
      })
      .then(function (response) {
        // //console.log(response);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };
  const saveAll = () => {
    saveAvatar();
    saveNickname();
    saveFrase();
  };

  const saveAvatar = () => {
    axios
      .post(config.url_api_dev + '/avatar/save', {
        avatar: user.avatar,
        email: user.email,
      })
      .then(function (response) {
        ////console.log(response);
      })
      .catch(function (error) {
        //console.log(error);
      });
  };

  return (
    <userContext.Provider
      value={{
        config,
        user,
        handleUserUpdate,
        handleConfigUpdate,
        setConfig,
        handleActiveNavLink,
        navActive,
        saveFrase,
        saveAll,
        isLogged,
        setIsLoged,
        uploading,
        setUploading,
      }}
    >
      {children}
    </userContext.Provider>
  );
};
