import { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import axios from 'axios';

export default NiceModal.create(({ isOpen, onClose }) => {
  const modal = useModal();
  const [instagram, setInstagram] = useState('');
  const [twitter, setTwitter] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [telegram, setTelegram] = useState('');
  const [onlyfans, setOnlyfans] = useState('');
  const [fansly, setFansly] = useState('');

  const handleSubmit = () => {
    const data = {
      instagram,
      tiktok,
      telegram,
      onlyfans,
      fansly,
      ruleta: sessionStorage.ruletahash,
      publicKey: sessionStorage.public_key,
    };

    axios
      .post('/guardar-redes', data)
      .then(response => {
        // Lógica después de enviar los datos al backend
        console.log(response.data);
        onClose();
      })
      .catch(error => {
        // Manejo de errores
        console.error('Error:', error);
      });
  };

  return (
    <Modal
      id="redes"
      closeOnOverlayClick={false}
      isOpen={modal.visible}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Agregar perfiles de redes sociales</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Instagram</FormLabel>
            <Input
              placeholder="instagram.com"
              value={instagram}
              onChange={e => setInstagram(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Twitter</FormLabel>
            <Input
              placeholder="twitter.com"
              value={twitter}
              onChange={e => setTwitter(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>TikTok</FormLabel>
            <Input
              placeholder="tiktok.com"
              value={tiktok}
              onChange={e => setTiktok(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Telegram Group</FormLabel>
            <Input
              placeholder="Telegram Group"
              value={telegram}
              onChange={e => setTelegram(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>OnlyFans</FormLabel>
            <Input
              placeholder="onlyfans.com"
              value={onlyfans}
              onChange={e => setOnlyfans(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Fansly</FormLabel>
            <Input
              placeholder="fansly.com"
              value={fansly}
              onChange={e => setFansly(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancelar
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Guardar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
