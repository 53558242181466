import React, { Component } from 'react';

import FineUploaderTraditional from 'fine-uploader-wrappers';
import Gallery from 'react-fine-uploader';
import { IoMdCloudUpload } from 'react-icons/io';

import 'react-fine-uploader/gallery/gallery.css';

import axios from 'axios';
import { Icon, Box } from '@chakra-ui/react';
const uploader = new FineUploaderTraditional({
  options: {
    chunking: {
      enabled: false,
    },
    deleteFile: {
      enabled: true,
      endpoint: '/endpoint.php',
    },
    request: {
      endpoint: '/endpoint.php',
    },
    retry: {
      enableAuto: true,
    },
  },
});

// 2. Use the `as` prop
function Coso() {
  return (
    <Box width="100%">
      <Icon boxSize="25px" as={IoMdCloudUpload} />
    </Box>
  );
}

const fileInputChildren = <Coso />;
const imageExtensionRegex = /\.(jpe?g|png|gif|bmp|webp)$/i;
const videoExtensionRegex = /\.(mp4|mov|avi|wmv|flv|3gp)$/i;
const RESPONSE_JSON = {};
var rel = [];
uploader.on('onComplete', (id, name, responseJSON, xhr) => {
  //console.log('oncompleteee');
  RESPONSE_JSON[id] = responseJSON;

  if (videoExtensionRegex.test(name.toLowerCase()))
    rel.push({ id: id, tipo: 'video', uuid: responseJSON.uuid, name: name });
  if (imageExtensionRegex.test(name.toLowerCase()))
    rel.push({ id: id, tipo: 'foto', uuid: responseJSON.uuid, name: name });
});
var fotos = 0;
var videos = 0;
uploader.on('onUpload', (id, name) => {
  sessionStorage.subiendo = true;
});
uploader.on('onAllComplete', (succeeded, failed) => {
  sessionStorage.removeItem('subiendo');
  const successfulUploadResponses = succeeded.map(id => RESPONSE_JSON[id]);
  //var url="https://server-mb9m.onrender.com/postPremio";
  fotos = 0;
  videos = 0;
  successfulUploadResponses.map((item, i) => {
    if (videoExtensionRegex.test(item.uploadName.toLowerCase())) videos++;
    if (imageExtensionRegex.test(item.uploadName.toLowerCase())) fotos++;
  });
  var titulo = '';
  if (fotos > 0) {
    titulo =
      fotos + ' Foto' + (fotos > 1 ? 's' : '') + (videos > 0 ? ', ' : '');
  }
  if (videos > 0) {
    titulo += videos + ' Video' + (videos > 1 ? 's' : '') + '.';
  }
  //console.log(fotos, videos, titulo);
  sessionStorage.titulo = titulo;
  sessionStorage.objetomedia = JSON.stringify(successfulUploadResponses);
});

uploader.on('onDeleteComplete', (succeeded, failed) => {
  var temp_obj_media = JSON.parse(sessionStorage.objetomedia);
  var temp = [];
  rel.map((item, i) => {
    if (item.uuid === RESPONSE_JSON[succeeded].uuid)
      temp_obj_media?.forEach((element, i) => {
        if (element.uuid !== item.uuid) temp.push(element);
      });
  });
  videos = 0;
  fotos = 0;
  temp.map((item, i) => {
    if (
      item.uploadName.toLowerCase().includes('mp4') ||
      item.uploadName.toLowerCase().includes('mov')
    )
      videos++;
    if (
      item.uploadName.toLowerCase().includes('jpg') ||
      item.uploadName.toLowerCase().includes('png')
    )
      fotos++;
  });
  var titulo = '';
  if (fotos > 0) {
    titulo =
      fotos + ' Foto' + (fotos > 1 ? 's' : '') + (videos > 0 ? ', ' : '');
  }
  if (videos > 0) {
    titulo += videos + ' Video' + (videos > 1 ? 's' : '') + '.';
  }
  sessionStorage.titulo = titulo;
  sessionStorage.objetomedia = JSON.stringify(temp);
  //console.log(temp);
});

class UploadComponent extends Component {
  render() {
    return (
      <Gallery
        dropzone-content={'.'}
        fileInput-children={fileInputChildren}
        uploader={uploader}
        maxHeight="200px"
      />
    );
  }
}

export default UploadComponent;
