import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaInstagram, FaTwitter, FaYoutube, FaTelegram } from 'react-icons/fa';
import { ReactNode } from 'react';

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode,
  label: string,
  href: string,
}) => {
  return (
    <chakra.button
      bg={'pink.500'}
      rounded={'full'}
      color={'white'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: 'black',
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
      bg={'#eed9f2'}
      color={useColorModeValue('gray.700', 'gray.200')}
      mt={'49px'}
    >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
      >
        <img
          src="https://www.putiruleta.com/loguitocopado.svg"
          alt="SVG Image"
          width="203px"
        />
        <Text>© 2022 PutiBot LLC.</Text>
        <Stack direction={'row'} spacing={6}>
          <SocialButton
            label={'Telegram'}
            href={'https://t.me/Creadoras_argentina'}
          >
            <FaTelegram />
          </SocialButton>
          <SocialButton
            label={'Instagram'}
            href={'https://instagram.com/creadoras_argentina'}
          >
            <FaInstagram />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
}
