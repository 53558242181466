import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Text,
  FormControl,
  FormLabel,
  FormErrorMessage,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Alert,
  Box,
  AlertIcon,
  VStack,
  Spinner,
  HStack,
  Input,
} from '@chakra-ui/react';
import axios from 'axios';
import { FaLightbulb } from 'react-icons/fa';

function PopupPacks({ urlPagos, onClose }) {
  const handlePaidOne = () => {
    validateEmail();
    if (!mail) return;
    if (sessionStorage.pais === 'UY') {
      //console.log(urlPagos);
      window.location.href = urlPagos
        .split(',')
        .filter(url => url.includes('.com.uy'))[0];
    }
    if (sessionStorage.pais === 'AR') {
      //console.log(urlPagos);
      window.location.href = urlPagos
        .split(',')
        .filter(url => url.includes('.com.ar'))[0];
    }
  };
  const [preciosPack, setPreciosPack] = useState([]);
  const [loading2, setIsLoading2] = useState(false);
  const [loading3, setIsLoading3] = useState(false);
  const precio = sessionStorage.getItem('precio');
  useEffect(() => {
    if (sessionStorage.mail) setEmail(sessionStorage.mail);
    if (localStorage.mail) setEmail(localStorage.mail);
    if (precio != null) {
      console.log('hola');
      const pack2 = precio * 1.5;
      const pack3 = precio * 2;
      setPreciosPack([pack2.toFixed(0), pack3.toFixed(0)]);
      console.log(preciosPack);
    }
  }, [precio]);

  const resultadoMemoizado = useMemo(() => {
    if (preciosPack.length > 0) {
      return preciosPack;
    } else {
      // Manejar el caso cuando preciosPack aún no está actualizado
      return [];
    }
  }, [preciosPack]);
  const [mail, setMail] = useState(false);
  const handleComprarTiros = e => {
    validateEmail();
    if (!mail) return;
    if (e.includes('|2|')) {
      setIsLoading2(true);
    }
    if (e.includes('|3|')) {
      setIsLoading3(true);
    }
    console.log(e);
    axios
      .get('https://server-mb9m.onrender.com/compra-pack?ladata=' + btoa(e))
      .then(res => (window.location.href = res.data));
  };

  const [email, setEmail] = useState('');

  const [error, setError] = useState('');
  const validateEmail = () => {
    if (!email.includes('@')) {
      setMail(false);
      setError('Corrige este campo');
    } else {
      setMail(true);
      localStorage.setItem('mail', email);
      sessionStorage.setItem('mail', email);
      setError('');
    }
  };
  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  return (
    <>
      <Modal isOpen={true} onClose={onClose} alignItems="center">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            textAlign="center"
            fontSize="2xl"
            fontWeight="bold"
            color="#343036"
          >
            Comprar tiros
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Button
              colorScheme="red"
              width="100%"
              height="auto"
              my={2}
              py={7}
              onClick={handlePaidOne}
              bg="rgb(238, 217, 242)"
              _hover={{ bg: '#b65796', color: '#EEE' }}
              _active={{ bg: '#000' }}
              color="#000"
              fontSize="xl"
              fontWeight="bold"
            >
              <VStack spacing={2} align="center">
                <HStack spacing={2} align="center">
                  <Text fontWeight="normal">1 Tiro - $</Text>{' '}
                  <Text fontWeight="bold" fontSize={'35px'}>
                    {sessionStorage.precio}{' '}
                  </Text>
                </HStack>
              </VStack>
            </Button>
            <FormControl isInvalid={error}>
              <FormLabel htmlFor="email">Tu email:</FormLabel>
              <Input
                id="email"
                placeholder={email ? email : 'Escribe tu email'}
                type="email"
                value={email}
                onChange={handleEmailChange}
                required
                onBlur={validateEmail}
              />
              <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>

            <VStack spacing={2} align="center">
              <Button
                colorScheme="red"
                width="100%"
                height="auto"
                my={2}
                py={7}
                onClick={() =>
                  handleComprarTiros(
                    sessionStorage.ruletahash +
                      '|2|' +
                      sessionStorage.pais +
                      '|' +
                      email
                  )
                }
                bg="rgb(238, 217, 242)"
                _hover={{ bg: '#b65796', color: '#EEE' }}
                _active={{ bg: '#000' }}
                color="#000"
                fontSize="xl"
                fontWeight="bold"
                isLoading={loading2}
                spinner={
                  <Spinner
                    thickness="24px"
                    speed="0.65s"
                    emptyColor="#a5e0cf"
                    color="#f5a7d5"
                    style={{
                      position: 'relative',
                      zIndex: 7,
                      width: '53px',
                      height: '53px',
                      margin: '2% auto',
                      transform: 'translate( -50%, -50% )',
                    }}
                  />
                }
              >
                <VStack spacing={2} align="center">
                  <HStack spacing={2} align="center">
                    <Text>2 Tiros - $</Text>
                    <Text fontSize={'35px'}>{resultadoMemoizado[0]}</Text>
                  </HStack>
                  <Box fontWeight="normal" textAlign="center" mt={-1}>
                    <small>
                      Paga 1 (+ <strong>uno al 50%</strong>)
                    </small>
                  </Box>

                  <Badge
                    colorScheme="red"
                    bg={'black'}
                    color={'white'}
                    fontSize="xs"
                    mt={2}
                    mb={-1}
                    p={3}
                  >
                    Más Comprado
                  </Badge>
                </VStack>
              </Button>
            </VStack>
            <Button
              colorScheme="red"
              width="100%"
              height="auto"
              my={2}
              py={7}
              onClick={() =>
                handleComprarTiros(
                  sessionStorage.ruletahash +
                    '|3|' +
                    sessionStorage.pais +
                    '|' +
                    email
                )
              }
              bg="rgb(238, 217, 242)"
              _hover={{ bg: '#b65796', color: '#EEE' }}
              _active={{ bg: '#000' }}
              color="#000"
              fontSize="xl"
              fontWeight="bold"
              isLoading={loading3}
              spinner={
                <Spinner
                  thickness="24px"
                  speed="0.65s"
                  emptyColor="#a5e0cf"
                  color="#f5a7d5"
                  style={{
                    position: 'relative',
                    zIndex: 7,
                    width: '53px',
                    height: '53px',
                    margin: '2% auto',
                    transform: 'translate( -50%, -50% )',
                  }}
                />
              }
            >
              <VStack spacing={2} align="center">
                <HStack spacing={2} align="center">
                  <Text fontWeight="normal">3 Tiros - $</Text>
                  <Text fontSize={'35px'}>{resultadoMemoizado[1]}</Text>
                </HStack>
                <Box fontWeight="normal" textAlign="center" mt={-1}>
                  <small>
                    Paga 2 y <strong>Tira 3</strong> (uno de regalo)
                  </small>
                </Box>
              </VStack>
            </Button>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PopupPacks;
