import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import {
  Box,
  Heading,
  Text,
  VStack,
  Center,
  Spinner,
  Button,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Formulario from './Formulario';

function DescargaIndividual() {
  const [toggleShow, setToggleShow] = useState(false);
  const [urlDescarga, setUrlDescarga] = useState('');
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const paymentId = query.get('payment_id');
  console.log(paymentId);
  // Obtener payment_id y metadata de los parámetros de la URL
  const [unaVez, setUnaVez] = useState(false);
  // Realizar la solicitud HTTP POST al endpoint en PHP
  const sendRequestToPHP = async () => {
    try {
      const response = await axios.post(
        'https://putiruleta.com/descarga/individual.php',
        {
          paymentId,
        }
      );
      console.log('Respuesta del servidor PHP:', response.data);
      if (!unaVez) {
        setUnaVez(true);
        // Iniciar la descarga utilizando navigate() si es necesario
        console.log(
          'https://putiruleta.com/descarga/' + response.data.substring(6)
        );
        setUrlDescarga(
          'https://putiruleta.com/descarga/' + response.data.substring(6)
        );
      }
    } catch (error) {
      console.log(error);
      // Manejar el error de la solicitud al servidor PHP si es necesario
    }
  };

  // Llamar a la función para enviar la solicitud al servidor PHP

  useEffect(() => {
    sendRequestToPHP();
    setTimeout(() => {
      setToggleShow(true);
    }, 10000);
  }, []);

  const handleDownload = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = 'https://putiruleta.com/descarga/' + paymentId + '.zip'; // Reemplaza con la URL del archivo .zip
    downloadLink.target = '_blank';
    downloadLink.download = paymentId + '.zip'; // Nombre del archivo que se descargará
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // Renderizar la página de éxito o redirigir a otra página si es necesario
  return (
    <Box
      h="100vh"
      w="100vw"
      style={{ backgroundImage: "url('fondo_kawaii_putiruleta4.jpg')" }}
    >
      <VStack spacing={4} style={{ backgroundColor: 'white', height: '100vh' }}>
        <Heading as="h1" size="2xl">
          Pago exitoso
        </Heading>
        <Text>Iniciando Descarga</Text>
        <small>Puede demorar si el archivo es muy grande</small>
        {toggleShow && (
          <>
            <Text>Si la descarga no comienza haz click aqui: </Text>
            <Button onClick={handleDownload}>Descargar Archivo</Button>
          </>
        )}
        <Text>Tambien podemos enviarte por email el link:</Text>
        <Formulario paymentId={paymentId} />
        <Text>Soporte tecnico: hola@putiruleta.com</Text>
        <Spinner
          thickness="7px"
          speed="0.65s"
          emptyColor="#a5e0cf"
          color="#f5a7d5"
          style={{
            position: 'relative',
            zIndex: 7,
            width: '75px',
            height: '75px',
            margin: '1% auto',
            transform: 'translate( -50%, -50% )',
          }}
        />
      </VStack>
    </Box>
  );
}

export default DescargaIndividual;
