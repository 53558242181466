import React, { ReactNode, useEffect, useState, useRef } from 'react';
import { NavLink, useNavigate, useParams, useLocation } from 'react-router-dom';
import useAxios from 'axios-hooks';

import {
  ChakraProvider,
  Box,
  Image,
  Badge,
  Text,
  Icon,
  Stack,
  Avatar,
  AvatarBadge,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
  Grid,
  Switch,
  InputGroup,
  InputRightElement,
  Flex,
  Tag,
  Heading,
} from '@chakra-ui/react';
import {
  StarIcon,
  EmailIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';

function PremiosPanel(props) {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();

  let params = useParams();

  var url = window.location;
  var pathname = url.pathname; // Obtiene la parte del path de la URL ("/ruleta-pack/0reijo/")

  // Extrae el valor de "0reijo" del pathname
  var partesPathname = pathname.split('/');
  var ruleta_hash = partesPathname[partesPathname.length - 2]; // Obtiene el valor antes del último slash ("/")

  const { premios, handleNuevoTiro } = props;
  const [
    { data: getDataPago, loading: getLoadingPago, error: getErrorPago },
    refetchPago,
  ] = useAxios(
    {
      //'https://server-mb9m.onrender.com/getPutiruleta/' + sessionStorage.ruletahash
      url:
        'https://server-mb9m.onrender.com/getMultiPremio/' +
        query.get('payment_id') +
        '/' +
        sessionStorage.getItem('pais') +
        '/' +
        ruleta_hash,
      useCache: false,
    },
    { manual: true }
  );
  const [giros, setGiros] = useState();
  const [hashes, setHashes] = useState();

  useEffect(() => {
    refetchPago().then(res => {
      var giros = JSON.parse(res.data.giros);
      if (giros === 1) localStorage.setItem('terminado', true);
    });

    setHashes(localStorage.ganados);
  }, []);

  const largo = premios.length;
  if (hashes != null)
    return (
      <Stack ml={4} spacing={2} mt={4} mr={4} width={'103%'}>
        {premios.reverse().map((item, i) => (
          <Alert
            key={item.hash}
            style={{
              display: i > 0 && hashes.includes(item.hash) ? 'flex' : 'none',
            }}
            variant="left-accent"
            status="success"
          >
            <AlertIcon />
            <AlertTitle>Ganaste:</AlertTitle>
            <AlertDescription mr={1}>{item.titulo}</AlertDescription>
          </Alert>
        ))}
      </Stack>
    );
}

export default PremiosPanel;
