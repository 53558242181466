import { ReactNode, useState } from 'react';
import {
  Box,
  Flex,
  Avatar,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import TelegramLoginButton from 'react-telegram-login';
import Help from './Help';
import axios from 'axios';

const NavLink = ({ children }: { children: ReactNode }) => (
  <Link
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}
  >
    {children}
  </Link>
);

export default function Navbar(props) {
  const [mostrarPopup, setMostrarPopup] = useState();
  const [listo, setListo] = useState(false);
  const handleChildClick = () => {
    setMostrarPopup(true);
  };

  const handleTelegramResponse = respuesta => {
    axios
      .post('https://server-mb9m.onrender.com/auth/telegram', {
        respuesta,
        publickey:
          sessionStorage.public_key != null ? sessionStorage.public_key : null,
      })
      .then(res => {
        setListo(true);
        console.log(res);
      });
  };

  const onDale = () => {
    setMostrarPopup(false);
  };

  const handleClose = () => {
    setMostrarPopup(false);
  };

  const handleClosePopup = nuevoValor => {
    setMostrarPopup(nuevoValor); // Actualiza el valor de la variable con el nuevo valor
  };

  return (
    <>
      <Box bg={'#eed9f2'} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <Box>
            {' '}
            <img
              src="https://www.putiruleta.com/loguito.jpg"
              alt="putiruleta Image"
              style={{ height: '50px', width: 'auto' }}
            />
          </Box>
          {mostrarPopup && <Help onDale={handleClosePopup} />}
          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7}>
              <Menu>
                <Box pt={'7px'}>
                  {sessionStorage.public_key && !listo && (
                    <TelegramLoginButton
                      dataOnauth={handleTelegramResponse}
                      botName="putipromo_bot"
                    />
                  )}
                </Box>
                <Button
                  onClick={handleChildClick}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}
                >
                  <img
                    src="https://www.putiruleta.com/help.svg"
                    alt="SVG Image"
                    style={{ height: '50px', width: 'auto' }}
                  />
                </Button>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
