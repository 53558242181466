import { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  ModalCloseButton,
  Stack,
  Spinner,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper';
import './styles.css';

function PremioViewr({ hash, onClose }) {
  const [isLoading, setIsLoading] = useState(true); // Agregada esta línea

  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onClose(); // Llama a la función de cierre pasada como prop
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [elsrc, setElsrc] = useState([]);

  useEffect(() => {
    const tmbs = axios
      .get(
        'https://putiruleta.com/files/scandir.php?hash=' +
          sessionStorage.ruletahash
      )
      .then(res => {
        var tmp = [];
        console.log('thumbs', res);
        if (res.data.length > 0) {
          res.data.map((item, i) => {
            if (i > 1 && item.includes(hash.val)) {
              console.log('thumb item', item);
              console.log(sessionStorage.ruletahash + '/' + item);
              tmp.push(sessionStorage.ruletahash + '/' + item);
            }
          });
          console.log(tmp);
          setIsLoading(false);
          setElsrc(tmp);
        }
        console.log('aca esta-2n-', elsrc, elsrc.length);
      });
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton onClick={handleClose} />
          <ModalBody style={{ minHeight: '600px!important' }}>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%', // O ajusta esta altura según tu diseño
                }}
              >
                <Spinner size="xl" style={{ zIndex: 1 }} />
              </div>
            ) : elsrc.length > 1 ? (
              <div>
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={false}
                  navigation={elsrc.length > 1 ? true : false}
                  loop={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 2,
                    },
                    768: {
                      slidesPerView: 1,
                      spaceBetween: 2,
                    },
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 2,
                    },
                  }}
                >
                  {elsrc &&
                    elsrc.map((item, i) => {
                      return (
                        <SwiperSlide key={i} backgroundcolor="transparent">
                          <div
                            position={'relative'}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              flexDirection: 'row',
                            }}
                          >
                            <img
                              key={i}
                              src={'https://putiruleta.com/files/' + item}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                minHeight: '251px',
                                height: '251px',
                                minWidth: '251px',
                              }}
                              alt={i}
                            />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>
              </div>
            ) : (
              <img
                src={'https://putiruleta.com/files/' + elsrc[0]}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '251px',
                  height: '251px',
                  minWidth: '251px',
                }}
              />
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PremioViewr;
