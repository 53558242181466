import { useState } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  Center,
  Heading,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

function Help({ onDale }) {
  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onDale(false);
  };

  const handleTelegram = () => {
    window.location.href = 'https://t.me/creadoras_argentina_vip';
  };

  const handleLimpiarCache = () => {
    sessionStorage.clear();
    setIsOpen(false);
    onDale(false);
    window.location.reload(false);
  };
  const isSmall = useMediaQuery('(max-width: 400px)');
  const isMedium = useMediaQuery('(max-width: 800px)');
  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Icon as={CheckIcon} color="green.500" mr={2} />
            ¿Necesitas ayuda?
          </Center>
        </ModalHeader>
        <ModalBody>
          <Text paddingBotom={'3px'}>
            Si estas teniendo problemas con tu ruleta intenta eliminar cache con
            el boton a continuacion. Tambien puedes ingresar en nuestro grupo
            vip y te ayudaremos personalmente.
          </Text>
          <SimpleGrid
            columns={isSmall[0] ? 1 : 2}
            spacing={1}
            w={isSmall[0] ? '320px' : isMedium[0] ? '380px' : '420px'}
          >
            <Button colorScheme="blue" onClick={handleTelegram}>
              Abrir grupo
            </Button>

            <Button colorScheme="blue" onClick={handleLimpiarCache}>
              Lipiar cache
            </Button>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default Help;
