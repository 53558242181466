import React, { useRef, useState, useEffect } from 'react';
import {
  useToast,
  Toast,
  ChakraProvider,
  extendTheme,
  Box,
  Stack,
  Text,
  Select,
  SimpleGrid,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Spinner,
  VStack,
  Grid,
  Button,
  Container,
  Input,
  Flex,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tag,
  TagLabel,
  TagCloseButton,
  HStack,
  Center,
  WrapItem,
  Wrap,
  Checkbox,
} from '@chakra-ui/react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper';
import './styles.css';
import { FaLock } from 'react-icons/fa';
import { ReactComponent as MercadoPagoIcon } from './mpagos.svg';

export default function PreviewContent(props) {
  const [thumbs, setThumbs] = useState(props.thumbs);
  const [isPaid, setIsPaid] = useState(false);

  useEffect(() => {
    //console.log('y esto', props.thumbs);
  }, []);

  const theme = extendTheme({
    styles: {
      global: (props: StyleFunctionProps) => ({
        body: {
          fontFamily: "'Poppins', sans-serif;",
        },
      }),
    },
  });
  const toast = useToast();
  const handleDescarga = () => {};
  const handleComprarDescarga = e => {
    if (!sessionStorage.test) {
      console.log(e);
      axios
        .get(
          'https://server-mb9m.onrender.com/generarPagoIndividual?ladata=' + e
        )
        .then(res => (window.location.href = res.data));
    } else {
      toast({
        title: 'Info',
        description: 'Las compras individuales estaran activas en 24 hrs',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (!props.thumbs)
    return (
      <ChakraProvider theme={theme}>
        <Container width="80vw" minWidth="60vw">
          <Stack spacing={2} minHeight="20%">
            <Spinner
              thickness="24px"
              speed="0.65s"
              emptyColor="#a5e0cf"
              color="#f5a7d5"
              style={{
                position: 'relative',
                zIndex: 7,
                width: '151px',
                height: '151px',
                margin: '37% auto',
                transform: 'translate( -50%, -50% )',
              }}
            />
          </Stack>
        </Container>
      </ChakraProvider>
    );

  return (
    <>
      <div>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
          }}
          navigation={true}
          loop={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 2,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 4,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 5,
            },
          }}
        >
          {props.thumbs &&
            props.thumbs.map((item, i) => {
              return (
                <SwiperSlide key={i} backgroundcolor="transparent">
                  <div
                    position={'relative'}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    <FaLock
                      style={{
                        position: 'absolute',
                        zIndex: '999',
                        fontSize: '157px',
                        width: '250px',
                        color: 'rgba(238, 217, 242, 0.9)',
                      }}
                    />
                    <div style={{ backgroundcolor: 'rgba(0, 0, 0, 0.4)' }}>
                      <Button
                        style={{ width: '100%' }}
                        backgroundColor={'#EED9F2'}
                        onClick={() =>
                          handleComprarDescarga(
                            btoa(
                              props.ruleta +
                                '|' +
                                item?.split('-')[1] +
                                '|' +
                                sessionStorage.pais_ruleta +
                                '|' +
                                sessionStorage.pais
                            )
                          )
                        }
                        color={'black'}
                        px={6}
                        colorScheme={'pink'}
                        bg={'pink.400'}
                        _hover={{ bg: 'pink.500', color: 'white' }}
                        borderBottomRadius="none"
                      >
                        <Box> Compra individual</Box>
                        <Box style={{ paddingLeft: '15px' }}>
                          <MercadoPagoIcon
                            style={{ width: '35px', height: '35px' }}
                          />
                        </Box>
                      </Button>
                      <img
                        style={{
                          filter: 'blur(5px)',
                          display: 'flex',
                          justifyContent: 'center',
                          minHeight: '251px',
                          height: '251px',
                          minWidth: '251px',
                        }}
                        src={'https://putiruleta.com/files/' + item}
                        alt={i}
                      />
                      <Button
                        style={{ width: '100%' }}
                        backgroundColor={'#EED9F2'}
                        onClick={() =>
                          handleComprarDescarga(
                            btoa(
                              props.ruleta +
                                '|' +
                                item?.split('-')[1] +
                                '|' +
                                sessionStorage.pais_ruleta +
                                '|' +
                                sessionStorage.pais
                            )
                          )
                        }
                        color={'black'}
                        px={6}
                        colorScheme={'pink'}
                        bg={'pink.400'}
                        _hover={{ bg: 'pink.500', color: 'white' }}
                        borderTopRadius="none"
                      >
                        <Box> Compra individual</Box>
                        <Box style={{ paddingLeft: '15px' }}>
                          <MercadoPagoIcon
                            style={{ width: '35px', height: '35px' }}
                          />
                        </Box>
                      </Button>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </>
  );
}
