import { useState } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SimpleGrid,
  Center,
  Heading,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

function CreadorasPopup({ onDale }) {
  const [isOpen, setIsOpen] = useState(true);
  const [isChecked, setIsChecked] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    onDale(false);
  };

  const handleTelegram = () => {
    window.location.href = 'https://t.me/creadoras_argentina';
  };

  const handleLimpiarCache = () => {
    sessionStorage.clear();
    setIsOpen(false);
    onDale(false);
    window.location.reload(false);
  };
  const isSmall = useMediaQuery('(max-width: 400px)');
  const isMedium = useMediaQuery('(max-width: 800px)');
  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Center>
            <Icon as={CheckIcon} color="green.500" mr={2} />
            ¿Buscas diversion?
          </Center>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text paddingBotom={'3px'}>
            Unite al canal telegram mas picante de America del Sur.
          </Text>
          <Text paddingBotom={'3px'}>
            Mas de 100 creadoras de contenido activas diariamente!
          </Text>
          <Text paddingBotom={'3px'}>
            Son todas pillas, ponete pillo! que esperas?
          </Text>
          <SimpleGrid
            columns={1}
            spacing={1}
            w={isSmall[0] ? '320px' : isMedium[0] ? '380px' : '420px'}
          >
            <Button colorScheme="blue" mt={2} onClick={handleTelegram}>
              Abrir grupo
            </Button>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreadorasPopup;
